<template>
  <v-layout class="fill-height">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      width="260"
      class="elevation-1"
      :right="$vuetify.rtl"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center">
          <img
            src="/images/v-logo-small1.png"
            height="48"
            alt="logo"
            class="mr-1 brand-logo"
          />
          <div>
            <!-- <div class="title font-weight-bold text-uppercase primary--text">{{ config.product.name }}</div>
            <div class="overline grey--text">{{ config.product.version }}</div> -->
          </div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="menu" />
    </v-navigation-drawer>
    <v-system-bar app window class="system-bar">
      <v-row>
        <v-col md="6" xs="3" class="text-right lob-bg">
          <div>&nbsp;</div>
          <span class="current-bus-line">Line Of Business:</span>
        </v-col>
        <v-col md="6" xs="9" class="lob-option">
          <v-select
            v-model="businessLineId"
            return-object
            :items="switchItems"
            item-text="line_of_business_name"
            item-value="line_of_business_id"
            label="Switch to another Business Line"
            required
          ></v-select>
        </v-col>
      </v-row>
    </v-system-bar>
    <!-- Toolbar -->
    <v-app-bar app flat color="surface">
      <v-card class="flex-grow-1 d-flex pa-1 mt-5 mx-1 secondary">
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->
          <!-- <v-text-field
            v-if="showSearch"
            append-icon="mdi-close"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            solo
            flat
            autofocus
            @click:append="showSearch = false"
          ></v-text-field> -->
          <div v-if="showSearch"></div>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon
              class="d-lg-none"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <!-- search input desktop -->
            <!-- <v-text-field
              ref="search"
              class="hidden-xs-only"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              rounded
            ></v-text-field> -->
            <div class="hidden-xs-only flex">
              <h3>{{ our_portal_name }} Portal ( {{ our_company_name }})</h3>
            </div>

            <v-spacer class="d-block d-sm-none"></v-spacer>

            <div class="d-block d-sm-none font-small">
              {{ our_company_name }}
            </div>

            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications />
            </div>

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-container fluid class="pt-3">
      <router-view class="py-2 fill-height"></router-view>

      <!-- DEMO PURPOSES DEFAULT ROUTER VIEW -->
      <div v-if="isDemo" class="py-2 fill-height">
        <slot></slot>
      </div>
    </v-container>

    <!-- <v-footer app inset>
      <div class="overline">
        Copyright © 2020 <a href="https://nfmp.gov.ng" target="_blank">NFMP</a>, All rights Reserved
      </div>
      <v-spacer></v-spacer>
      <div class="overline">
        Made with by <a href="https://nfmp.gov.ng" target="_blank">NFMP</a>
      </div>
    </v-footer> -->
  </v-layout>
</template>

<script>
import config from '@/configs'
import MainMenu from '@/components/navigation/MainMenu'
import ToolbarUser from '@/components/toolbar/ToolbarUser'
import ToolbarNotifications from '@/components/toolbar/ToolbarNotifications'
import myServices from '../../../../services/myservices'

import httpServ from '../../../../services/HTTPService'

// Demo menu content
import menu from './menu'
import mainmembermenu from './mainmembermenu'
import firstrunmenu from './firstrunmenu'
import menu3 from './menu3'

export default {
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarNotifications
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      membership_type: {
        Firstrun: 'Firstrun',
        Mainmember: 'Mainmember',
        FullMembership: 'Full Membership'
      },
      menu,
      mainmembermenu,
      firstrunmenu,
      menu3,
      config,
      drawer: null,
      showSearch: false,
      businessLineId: '',
      select: null,
      our_portal_name: '',
      our_company_name: ' ',
      switchItems: [],
      userProfile: ''
    }
  },
  watch: {
    businessLineId: {
      handler(newvalue) {
        //////console.log('item changed', newvalue)
        //////console.log('state id is ', this.stateId)
        //this.statelgas = this.alllga.filter((lga) => lga.state_id === this.stateId.id)
        //////console.log('all the lga is ', this.statelgas)
        const newUserProfile = myServices.getSessionItem('process_data')
        const memberData = httpServ.getSessionJson('memberData')

        const memberStatus =
          memberData.lobs['main_line_of_business'].membership_status

        console.log('memberStatus:', memberStatus)

        this.userProfile = newUserProfile

        if (membership_status === 'Firstrun') {
          /*const process_d = {
            data: this.userProfile.data,
            dashboard: this.userProfile.dashboard,
            usertype: this.membership_type.Mainmember,
            currentLOB: this.businessLineId.line_of_business_id,
            currentLOBname: this.businessLineId.line_of_business_name
          }

          myServices.setSessionItem('process_data', JSON.stringify(process_d))*/
        } else {
          /*const process_d = {
            data: this.userProfile.data,
            dashboard: this.userProfile.dashboard,
            usertype: this.membership_type.Firstrun,
            currentLOB: this.businessLineId.line_of_business_id,
            currentLOBname: this.businessLineId.line_of_business_name

          }
          myServices.setSessionItem('process_data', JSON.stringify(process_d))*/
        }
        //console.log('this is the new value ', newvalue)
        if (membership_status === 'Firstrun') {
          this.menu = this.firstrunmenu
          this.our_portal_name = this.membership_type.Firstrun
        }

        if (membership_status === 'Full Membership') {
          this.menu = this.mainmembermenu
          this.our_portal_name = this.membership_type.FullMembership
        }
      },
      immediate: true
    }
  },
  created() {
    const newUserProfile = myServices.getSessionItem('process_data')

    console.log('newUserProfile:',newUserProfile)

    this.userProfile = newUserProfile
    this.our_company_name = newUserProfile.dashboard.company.company_name

    console.log('this.userProfile:',this.userProfile)
    this.businessLineId = {
      line_of_business_id:
        this.userProfile.data.lobs.main_line_of_business.line_of_business_id,
      line_of_business_name:
        this.userProfile.data.lobs.main_line_of_business.line_of_business_name,
      membership_status:
        this.userProfile.data.lobs.main_line_of_business.membership_status,
      membership_status_rf:
        this.userProfile.data.lobs.main_line_of_business.membership_status_rf
    }

    const swItems = [
      {
        line_of_business_id:
          this.userProfile.data.lobs.main_line_of_business.line_of_business_id,
        line_of_business_name:
          this.userProfile.data.lobs.main_line_of_business
            .line_of_business_name,
        membership_status:
          this.userProfile.data.lobs.main_line_of_business.membership_status,
        membership_status_rf:
          this.userProfile.data.lobs.main_line_of_business.membership_status_rf
      }
    ]

    if (
      this.userProfile.data.lobs.second_line_of_business.line_of_business_id > 0
    ) {
      const second_line = {
        line_of_business_id:
          this.userProfile.data.lobs.second_line_of_business
            .line_of_business_id,
        line_of_business_name:
          this.userProfile.data.lobs.second_line_of_business
            .line_of_business_name,
        membership_status:
          this.userProfile.data.lobs.second_line_of_business.membership_status,
        membership_status_rf:
          this.userProfile.data.lobs.second_line_of_business
            .membership_status_rf
      }

      swItems.push(second_line)
    }

    this.switchItems = swItems
  },
  mounted() {
    //
    /* if (this.userProfile.data.lobs.main_line_of_business.line_of_business_id > 0) {
      this.switchItems.push(this.userProfile.data.lobs.main_line_of_business)
      this.businessLineId = this.userProfile.data.lobs.main_line_of_business
      console.log('yes we have main LOB', this.businessLineId)
    }

    if (this.userProfile.data.lobs.second_line_of_business.line_of_business_id > 0) {
      this.switchItems.push(this.userProfile.data.lobs.second_line_of_business)
      console.log('yes we have second LOB', this.switchItems)
    } */
  }
}
</script>
<style scoped>
.lob-bg {
  background-color: #0a6003 !important;
}

.lob-option {
  background-color: #49e80f !important;
}
.font-small {
  font-size: 13px !important;
}
.current-bus-line {
  text-align: right !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
}
.system-bar {
  background-color: #8b8988 !important;
}
.system-bar select {
  background-color: white !important;
}
.elevation-1 {
  background-color: #a5d6a7 !important;
}
.app-title {
  font-size: 30px;
}

.brand-logo {
  width: 240px !important;
  height: 30px !important;
}

.top-switch {
  background-color: black !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.text-h4,
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  font-weight: 400 !important;
}
</style>