<template>
  <Layout is-demo>
    <div class="pb-5">
      <h1 class="text-h4">Warehouses</h1>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!--<v-toolbar-title>My CRUD</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>-->
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add new warehouse/store
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form v-model="valid">
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12" class="py-0">
                            <v-text-field
                              v-model="title"
                              label="Description/Name*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-text-field
                              v-model="capacity"
                              label="Capacity(MT)*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-select
                              v-model="ownership_status_item"
                              return-object
                              :items="ownership_status_arr"
                              item-text="title"
                              item-value="id"
                              :selected="ownership_status_item.id"
                              label="Ownership Status*"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6" class="py-0">
                            <v-select
                              v-model="state_item"
                              return-object
                              :items="state_arr"
                              item-text="title"
                              item-value="id"
                              :selected="state_item.id"
                              label="State of Residence"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6" class="py-0">
                            <v-select
                              v-model="lga_item"
                              return-object
                              :items="lga_arr"
                              item-text="title"
                              item-value="id"
                              label="LGA of Residence"
                              :selected="lga_item.id"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                              v-model="address"
                              label="Address"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <h4 class="font-weight-bold">Existing Equipment</h4>
                            <span
                              class="caption"
                            >Select the equipments you have in the
                              warehouse</span>
                          </v-col>
                          <div
                            v-for="item in equipmentsData"
                            :key="item"
                            class="ps-3 py-0 ms-2"
                          >
                            <v-checkbox
                              v-model="selections"
                              hide-details
                              ripple
                              class="caption"
                              :label="item"
                              :value="item"
                            ></v-checkbox>
                          </div>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :disabled="postWarehouseBtnIsActive"
                        @click="postWarehouseData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="editItem(item)">
                mdi-file-document-edit
              </v-icon>
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <BackButton />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import BackButton from './assets&products/BackButton'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout,
    BackButton
  },
  data: () => ({
    memberData: {},
    currentLOBDetails: {},
    prePostData: {},
    pageData: {},
    equipmentsData: [],

    dialog: false,
    dialogDelete: false,
    tableLoading: true,
    singleSelect: true,
    tableNoDataText: 'Thus table has no record!',

    snackbar: false,
    snackbartext: '',

    warehouse_id: 0,
    title: '',
    capacity: '',
    address: '',
    ownership_status_item: {},
    ownership_status_arr: [],
    state_item: {},
    state_arr: [],
    lga_item: {},
    lga_arr: [],
    lga_complete: [],
    selections: [],
    raw_equipments_Data: [],

    valid: false,
    postWarehouseBtnIsActive: false,
    postWarehouseBtnText: 'Send',

    headers: [
      {
        text: 'Description/Name',
        align: 'start',
        sortable: false,
        value: 'title'
      },
      { text: 'Capacity', value: 'capacity' },
      { text: 'State', value: 'state' },
      { text: 'L.G.A', value: 'lga' },
      { text: 'Equipments(seperated by commas)', value: 'equipments' },
      { text: 'Modify', value: 'actions', sortable: false }
    ],
    tableData: [],

    editedIndex: -1,
    editedItem: {
      warehouse_id: 0,
      title: '',
      capacity: '',
      address: '',
      ownership_status_item: {},
      lga_item: {},
      state_item: {},
      existing_equipments:[]
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Add New Warehouse'
        : 'Edit Existing Warehuse'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    state_item: {
      handler(newValue) {
        this.lga_arr = this.lga_complete.filter(
          (lga) => lga.state_id === this.state_item.id
        )
        console.log(this.lga_arr)
      },
      immediate: true
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
    this.getPageData()
  },

  methods: {
    getTableData: function () {
      const url = httpServ.getMemberUrl('/tables/warehouse')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.tableData = result.data.warehouses
      })
      this.tableLoading = false
    },
    getPageData: function (mode) {
      const url = httpServ.getMemberUrl('/formOptions/warehouse')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.ownership_status_arr = result.data.ownership_status_rf
        this.state_arr = result.data.state_rf
        this.lga_complete = result.data.lga_rf

        this.raw_equipments_Data = result.data.equipments
        const eqData = []

        result.data.equipments.forEach((item) => {
          eqData.push(item.title)
        })
        this.equipmentsData = eqData
      })
      this.tableLoading = false
    },
    getNewData: function () {
      const existing_equipments = []
      const selected = this.selections

      this.raw_equipments_Data.forEach((item) => {
        if (selected.includes(item.title)) existing_equipments.push(item.id)
      })
      const data = {
        status: 'new',
        title: this.title,
        capacity: this.capacity,
        ownership_status_rf: this.ownership_status_item.id,
        state_rf: this.state_item.id,
        lga_rf: this.lga_item.id,
        address: this.address,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id,
        existing_equipments: existing_equipments
      }

      return data
    },
    getUpdatedData: function () {
      const existing_equipments = []
      const selected = this.selections

      if (selected !== undefined) {
        this.raw_equipments_Data.forEach((item) => {
          if (selected.includes(item.title)) existing_equipments.push(item.id)
        })
      }
      const data = {
        status: 'update',
        title: this.title,
        capacity: this.capacity,
        ownership_status_rf: this.ownership_status_item.id,
        state_rf: this.state_item.id,
        lga_rf: this.lga_item.id,
        address: this.address,
        warehouse_id: this.warehouse_id,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id,
        existing_equipments: existing_equipments
      }

      return data
    },
    postWarehouseData: function (mode) {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()

      this.updateProfileBtnIsActive = true
      this.updateProfileBtnText = 'Submiting ...'

      const url = httpServ.getMemberUrl('/account/warehouse')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close()
          this.getTableData()
        } else {
          //alert(result.message)
          this.snackbartext = result.message + ': ' + result.data.error
          this.snackbar = true
          this.signinBtnStatus = false
          this.signinBtnText = 'Update failed!'
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      /*this.editedItem = Object.assign({}, item)*/
      
      this.state_item.id = item.state_rf
      this.title = item.title
      this.capacity = item.capacity
      this.ownership_status_item.id = item.ownership_status_rf
      this.address = item.address

      console.log(item.existing_equipment)
      this.existing_equipments = item.existing_equipment
      this.lga_item.id = item.lga_rf

      this.mode = 'update'
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.state_item.id = 0
        this.title = ''
        this.capacity = ''
        this.ownership_status_item.id = 0
        this.address = ''

        this.existing_equipments = []
        this.lga_item.id = 0
        this.editedIndex = -1
      })
    }
  }
}
</script>

<style scoped>
</style>
//TODO update to post data