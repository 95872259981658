<template>
  <div>
    <QuaterlyReportFiling />
  </div>
</template>

<script>
import QuaterlyReportFiling from '@/nfmp_components/QuaterlyReportFiling.vue'
export default {
  components: {
    QuaterlyReportFiling
  }
}
</script>