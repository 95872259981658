<template>
  <div>
    <Notification />
  </div>
</template>

<script>
import Notification from '@/nfmp_components/Notification.vue'
export default {
  components: {
    Notification
  }
}
</script>