<template>
  <v-layout justify-center>
    <v-btn
      color="primary"
      large
      @click="function(){
        $router.push({ name: 'quaterlyReportFiling' })
      }"
    >
      Back To Quaterly Report Filing
    </v-btn>
  </v-layout>
</template>
<script>
export default {}
</script>