<template>
  <div>
    <ProducerSalesReportFiling v-if="currentLOBDetails.LOBId > 1"> </ProducerSalesReportFiling >
    <DistributorSalesReportFiling v-else> </DistributorSalesReportFiling >
  </div>
</template>

<script>
import httpServ from '../services/HTTPService'
import ProducerSalesReportFiling from '@/nfmp_components/ProducerSalesReportFiling.vue'
import DistributorSalesReportFiling from '@/nfmp_components/DistributorSalesReportFiling.vue'
export default {
  components: {
    ProducerSalesReportFiling,
    DistributorSalesReportFiling
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{}
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    /*this.currentLOBDetails.memberStatus = 
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId = this.memberData.lobs[this.memberData.displayLOB].line_of_business_id*/
    this.currentLOBDetails.memberStatus = 1 
  }
}

</script>