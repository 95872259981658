<template>
  <div>
    <PurchaseReportFiling />
  </div>
</template>

<script>
import PurchaseReportFiling from '@/nfmp_components/PurchaseReportFiling.vue'
export default {
  components: {
    PurchaseReportFiling
  }
}
</script>