<template>
  <div>
    <Ledger />
  </div>
</template>

<script>
import Ledger from '@/nfmp_components/Ledger2.vue'
export default {
  components: {
    Ledger
  }
}
</script>