<template>
  <div>
    <CompanyProfile />
  </div>
</template>

<script>
import CompanyProfile from '@/nfmp_components/CompanyProfile.vue'
export default {
  components: {
    CompanyProfile
  }
}
</script>