export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '#'  }
  ]
}, {
  text: 'Other',
  key: 'menu.others',
  items: [
    { icon: 'mdi-briefcase', key: 'menu.blank', text: 'Company Profile', link: '/companyprofile' },
    { key: 'menu.levels', text: 'Assets & Products',
      items: [
        { text: 'General View', link: '/assets_products' },
        { text: 'Warehouses/Stores/Storage Facilities', link: '/warehouses' },
        { text: 'Representative Summary', link: '/representatives' },
        { text: 'Fertilizer Products Summary', link: '/products' },
        { text: 'Fertilizer Packages Summary', link: '/packages' }
        /* { text: 'Warehouses/Stores/Storage Facilities' },
        { text: 'Representative Summary' },
        { text: 'Fertilizer Products Summary' },
        { text: 'Fertilizer Packages Summary' } */
        /* { text: 'Menu Levels 2.2',
          items: [
            { text: 'Menu Levels 3.1' },
            { text: 'Menu Levels 3.2' }
          ]
        } */
      ]
    },
    { icon: 'mdi-receipt', key: 'menu.blank', text: 'Generate Invoice', link: '' },
    { icon: 'mdi-cash-multiple', key: 'menu.blank', text: 'Make Payment', link: '' },
    { icon: 'mdi-account-cash', key: 'menu.blank', text: 'Transaction History', link: '' },
    { icon: 'mdi-account-cash', key: 'menu.blank', text: 'Notification', link: '' }
    
    /* ,
    { key: 'menu.disabled', text: 'Menu Disabled', disabled: true } */
  ]
}]
