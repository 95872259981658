<template>
  <div>
    <RemitaResponse />
  </div>
</template>

<script>
import RemitaResponse from '@/nfmp_components/RemitaResponse.vue'
export default {
  components: {
    RemitaResponse
  }
}
</script>