<template>
  <div>
    <Settings />
  </div>
</template>

<script>
import Settings from '@/nfmp_components/Settings.vue'
export default {
  components: {
    Settings
  }
}
</script>