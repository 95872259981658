import { render, staticRenderFns } from "./MainPortalLayout.vue?vue&type=template&id=afcca4d8&scoped=true&"
import script from "./MainPortalLayout.vue?vue&type=script&lang=js&"
export * from "./MainPortalLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainPortalLayout.vue?vue&type=style&index=0&id=afcca4d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afcca4d8",
  null
  
)

export default component.exports