<template>
  <div>
    <RemitaInvoice />
  </div>
</template>

<script>
import RemitaInvoice from '@/nfmp_components/RemitaInvoice.vue'
export default {
  components: {
    RemitaInvoice
  }
}
</script>