<template>
  <Layout is-demo>
    <div class="pb-2">
      <h1 class="text-h4">Purchase Report Filing</h1>
      <div class="caption" color="grey--text">
        To modify an exist entry click on the modify icon <v-icon>mdi-file-document-edit</v-icon> on 
        the Action column of the record you want modify<br/>
        To apply for Inspection Fee Exemption click the exemption icon <v-icon>mdi-cash-lock-open</v-icon> 
        on the Action column of the record you want to exempt. Be sure that the supplier have paid for it else you will be penalize!. 
      </div>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.fertilizer="{ item }">
              <span>{{ item.fertilizer | tableLen30 }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add Purchase Entry
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
                      <small
                        v-if="mode==='new'"
                        class="pa-2 red--text"
                      >Supplier certificate must be verified before you proceed</small>
                      <v-container>
                        <v-row v-if="mode==='new'" class="py-2">
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="supplier_certificate_number"
                              label="Supplier Certificate Number*"
                              :rules="certRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-btn
                              :loading="verifyBtnLoader"
                              :disabled="verifyBtnLoader"
                              large
                              rounded 
                              color="green lighten-2"
                              @click="verifyCertificate(1)"
                            >
                              Verify Supplier's Certificate</v-btn>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="12"
                            class="py-0"
                          >
                            <div class="grey--text" align="center" justify="center"> {{ supplier_name }}</div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="12"
                            class="py-0"
                          >
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="fertilizer_id_item"
                              return-object
                              :items="fertilizer_id_arr"
                              item-text="title"
                              item-value="id"
                              label="Fertilizer*"
                              :rules="[v => !!v || 'Fertilizer is required']"
                              :disabled="formDisabled"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="grade"
                              label="Grade*"
                              required
                              :disabled="formDisabled"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="tonnage"
                              label="Tonnage*"
                              required
                              :rules="numberRules"
                              :disabled="formDisabled"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="transaction_date"
                                  label="Transaction date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  :disabled="formDisabled" 
                                  :rules="dateRules"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="transaction_date"
                                :active-picker.sync="activePicker"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                :min="min_date"
                                @change="saveDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :disabled="formDisabled"
                        :loading="formBtnLoader"
                        @click="postFilingData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2" max-width="600px">
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        Apply For Exemption
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form
                      ref="form2"
                      v-model="valid2"
                      lazy-validation
                    >
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small><br/>
                      <small
                        v-if="mode==='new'"
                        class="pa-2 red--text"
                      >Supplier certificate must be verified before you proceed</small>
                      <v-container>
                        <v-row class="py-2">
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="exempted_supplier_certificate_number"
                              label="Exempted Supplier Certificate Number*"
                              :rules="certRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-btn
                              :loading="verifyBtnLoader"
                              :disabled="verifyBtnLoader"
                              large
                              rounded 
                              color="green lighten-2"
                              @click="verifyCertificate2"
                            >
                              Verify Supplier's Certificate</v-btn>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="12"
                            class="py-0"
                          >
                            <div class="grey--text" align="center" justify="center"> {{ supplier_name }}</div>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="12"
                            class="py-0"
                          >
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="py-0"
                          >
                            <v-select
                              v-model="documentType_item"
                              return-object
                              :items="documentItems"
                              item-text="title"
                              item-value="id"
                              label="Document Type*"
                              :rules="[v => !!v || 'Document Type is required']"
                              :disabled="formDisabled2"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="documentValue"
                              label="Permit Number*"
                              required
                              :disabled="formDisabled2"
                              :rules="certRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close2">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :disabled="formDisabled2"
                        :loading="formBtnLoader2"
                        @click="postFilingData2()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon v-if="isEditOk(item)" class="mr-2" @click="editItem(item)" >
                mdi-file-document-edit
              </v-icon> 
              <v-icon v-if="isEditOk(item)" class="mr-2" @click="addExemption(item)" >
                mdi-cash-lock-open
              </v-icon> 
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <BackButton />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import BackButton from './quaterlyReport/BackButton'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout,
    BackButton
  },
  data: () => ({
    memberData: {},
    currentLOBDetails: {},
    prePostData: {},
    pageData: {},

    dialog: false,
    dialogDelete: false,
    tableLoading: true,
    singleSelect: true,
    tableNoDataText: 'Thus table has no record!',
    dialog2: false,
    form2Title:'',

    snackbar: false,
    snackbartext: '',

    mode:'new',

    fertilizer_id: 0,
    grade: '',
    state_id: '',
    tonnage: '',
    purchase_id:'',
    supplier_certificate_number:'FISS000134A',
    supplier_certificate_number_4update:'',
    exempted_supplier_certificate_number:'',
    supplier_name: '',
    supplier_LOB:'',
    fertilizer_id_item: {},
    fertilizer_id_arr: [],
    state_id_item: {},
    state_id_arr: [],

    activePicker: null,
    transaction_date: null,
    menu: false,
    min_date:'',

    verifyBtnLoader: false,
    formDisabled: true,
    formBtnLoader: false,
    formDisabled2: true,
    formBtnLoader2: false,
    documentItems:  [
      { 'id':1,'title':'Import Export Permit Number' }, 
      { 'id':2,'title':'Exemption Permit Number' }],
    documentType_item:{},
    documentType:'',
    documentValue:'',
    
    valid: false,
    valid2:false,
    filingBtnLoader: false,

    certRules: [
      (v) => !!v || 'Certificate number is required',
      (v) => (v && v.length >= 10) || 'Certificate number must be greater than 10 characters'
    ],
    dateRules: [
      (v) => !!v || 'Date is required',
      (v) => (v  &&  Date.parse(v.replaceAll(' ',''))) || 'Date must be valid'
      //(v) => (v  &&  v instanceof Date && !isNaN(v)) || 'Date must be valid'
    ],
    numberRules: [
      (v) => !!v || 'Tonnage is required',
      (v) => (!isNaN(parseFloat(v))) || 'Tonnage must be a number',
      (v) => (v && v > 0 ) || 'Tonnage must greater than zero'
    ],

    headers: [
      {
        text: 'Fertiilizer',
        align: 'start',
        sortable: false,
        value: 'fertilizer'
      },
      /*{ text: 'Grade', value: 'grade' },*/
      { text: 'Tonnage(M/T)', value: 'tonnage' },
      { text: 'Supplier', value: 'supplier_certificate_number' },
      { text: 'Approval Status', value: 'status' },
      { text: 'Transaction Date', value: 'transaction_date' },   
      { text: 'Exemption Status', value: 'exemption' },  
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    tableData: [],
    editedIndex: -1
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Add New Purchase Record'
        : 'Modify Existing Record'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
    this.getPageData()
  },

  methods: {
    isEditOk: function(item) {
      if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }
    },
    saveDate (date) {
      this.$refs.menu.save(date)
    },
    getTableData: function () {
      const postData = this.prePostData

      postData.category = 'purchase'
      const url = httpServ.getTonnageReportUrl('/tonnageReport/member/getPurImpFilingDataTable')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.tableData = result.data
      })
      this.tableLoading = false
    },
    getPageData: function () {
      const url = httpServ.getTonnageReportUrl('/tonnageReport/member/getPurchaseFilingFormOptions')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.fertilizer_id_arr = result.data.fertilizers
        this.state_id_arr = result.data.states
        this.min_date = result.data.schedules.adjusted_date
      })
    },
    getNewData: function () {
      const data = {
        status: 'new',
        fertilizer_id: this.fertilizer_id_item.id,
        grade: this.grade,
        tonnage: this.tonnage,
        supplier_certificate_number: this.supplier_certificate_number,
        transaction_date: this.transaction_date,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        supplier_certificate_number:this.supplier_certificate_number_4update,
        purchase_id:this.purchase_id,
        tonnage: this.tonnage,
        grade: this.grade,
        fertilizer_id: this.fertilizer_id_item.id,
        transaction_date: this.transaction_date,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    postFilingData: function () {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()

      //validate
      
      if (this.$refs.form.validate()) {
        this.formBtnLoader = true 
    
        const url = httpServ.getTonnageReportUrl('/tonnageReport/member/savePurchaseFilingDetails')
        const resp = httpServ.postFetchWithToken(
          url,
          postData,
          this.memberData.jwt
        )

        resp.then((result) => {
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    postFilingData2: function () {
      const postData = this.prePostData

      postData.purchase_id = this.purchase_id
      postData.documentType = this.documentType_item.id
      postData.documentValue =  this.documentValue
      postData.exempted_supplier_certificate_number = this.exempted_supplier_certificate_number
      console.log(postData)
      //validate
      
      if (this.$refs.form2.validate()) {
        this.formBtnLoader = true 
    
        const url = httpServ.getTonnageReportUrl('/tonnageReport/member/applyForExemption')
        const resp = httpServ.postFetchWithToken(
          url,
          postData,
          this.memberData.jwt
        )

        resp.then((result) => {
          if (result.status === 200) {
            this.snackbartext = 'Entry was successful!'
            this.snackbar = true
            this.signinBtnText = 'Successful'
            this.close()
            this.getTableData()
          } else if (result.status === 422) {
            if (result instanceof SyntaxError) {
              // Unexpected token < in JSON
              //console.log('There was a SyntaxError', result)//checker
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      } else {
        console.log('error')
      }
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      this.supplier_certificate_number_4update = item.supplier_certificate_number
      this.purchase_id = item.purchase_id
      this.tonnage = item.tonnage
      this.grade = item.grade
      this.transaction_date = item.transaction_date
      this.fertilizer_id_item.id = item.fertilizer_id
      this.dialog = true
      ///
      this.formDisabled = false
      this.mode = 'update'
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.supplier_certificate_number = ''
        this.purchase_id = ''
        this.tonnage = ''
        this.grade = ''
        this.transaction_date = ''
        this.fertilizer_id_item.id = ''
        this.editedIndex = -1
        this.verifyBtnLoader = false
        this.formDisabled = true
        this.formBtnLoader = false
        this.mode = 'new'
      })
    },
    close2() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.supplier_certificate_number_exemption = ''
        this.documentValue = ''
        this.documentType = ''
      })
    },
    verifyCertificate: function() {
      const valid = false

      //if (valid) {
      const url = httpServ.getTonnageReportUrl('/tonnageReport/member/verifyCertificate')

      if (this.supplier_certificate_number === '') {
        this.snackbartext = 'Certificate Number field is empty!'
        this.snackbar = true

        return
      }
      const postData = this.prePostData

      this.verifyBtnLoader = true
      postData.certificate_number = this.supplier_certificate_number
      this.snackbartext = 'Verifing certificate, please wait!'
      this.snackbar = true
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.snackbartext = ''
        this.snackbar = false
        this.verifyBtnLoader = false
        if (result.data.message === 'No record!') {
          this.supplier_name = 'Result not found!'
        } else {
          this.supplier_name = '[' + result.data[0].lob + '] ' + result.data[0].companyName
          this.formDisabled = false
        }

      })
      //}
    },
    verifyCertificate2: function() {
      const url = httpServ.getTonnageReportUrl('/tonnageReport/member/verifyCertificate')

      if (this.exempted_supplier_certificate_number_exemption === '') {
        this.snackbartext = 'Certificate Number field is empty!'
        this.snackbar = true

        return
      }
      const postData = this.prePostData
      //postData.

      this.verifyBtnLoader = true
      postData.certificate_number = this.exempted_supplier_certificate_number
      this.snackbartext = 'Verifing certificate, please wait!'
      this.snackbar = true
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      this.formDisabled2 = false///
      resp.then((result) => {
        this.snackbartext = ''
        this.snackbar = false
        this.verifyBtnLoader = false
        if (result.data.message === 'No record!') {
          this.supplier_name = 'Result not found!'
        } else {
          this.supplier_name = '[' + result.data[0].lob + '] ' + result.data[0].companyName
          this.formDisabled2 = false
        }

      })
      //}
    },
    addExemption: function(item) {
      this.purchase_id = item.purchase_id
      this.dialog2 = true
    }
  }
}
</script>

<style scoped>
</style>
//TODO update to post data