<template>
  <div>
    <LegacyTransactionhistory />
  </div>
</template>

<script>
import LegacyTransactionhistory from '@/nfmp_components/LegacyTransactionHistory.vue'
export default {
  components: {
    LegacyTransactionhistory
  }
}
</script>