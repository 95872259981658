<template>
  <div>
    <ProductsStarter />
  </div>
</template>

<script>
import ProductsStarter from '@/components/ui/assetsandproducts/products/ProductsStarter.vue'
export default {
  components: {
    ProductsStarter
  }
}
</script>