<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon class="elevation-2" v-on="on">
        <v-badge
          color="success"
          dot
          bordered
          offset-x="10"
          offset-y="10"
        >
          <v-avatar size="40" color="grey">
            <v-img src="/images/user.png"></v-img>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-list dense nav>
      <v-list-item-group>
        <template v-for="(item, i) in menu">
          <v-divider
            v-if="item.text==='divider'"
            :key="`divider-${i}`"
          ></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :to="item.link"
            link
          >
            <v-list-item-icon>
              <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <!-- user menu list 
    <v-list dense nav>
        <v-list-item
          v-for="(item, index) in menu"
          :key="index"
          :exact="item.exact"
          :disabled="item.disabled"
          :to="item.link"
          link
        >
          <v-list-item-icon>
            <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-1"></v-divider>
    </v-list> -->
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  data() {
    return {
      menu: [
        { icon: 'mdi-view-dashboard-outline', text: 'Dashboard',link: '/dashboard' },
        { icon: 'mdi-cog-outline', text: 'Settings', link: '/settings'  },
        { text:'divider' },
        { icon: 'mdi-logout-variant', text: 'Logout', link: '/logout'  }
      ]
    }
  }
}
</script>
