<template>
  <div>
    <PackagesStarter />
  </div>
</template>

<script>
import PackagesStarter from '@/components/ui/assetsandproducts/packages/PackagesStarter.vue'
export default {
  components: {
    PackagesStarter
  }
}
</script>