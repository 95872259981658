<template>
  <Products />
</template>

<script>
import Products from '@/components/ui/assetsandproducts/products/Products.vue'

export default {
  components: {
    Products
  }
}
</script>