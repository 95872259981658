<template>
  <Layout1 is-demo>
    <h1 class="text-h4">Products</h1>
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    <v-divider class="my-2"></v-divider>
    <v-row justify="center">
    </v-row>
    <v-row>
      <v-col sm="12" md="12">
        <v-card class="d-card-height pt-2">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="4" class="py-0">
                <v-dialog
                  v-model="dialog"
                  persistent
                  width="unset"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Update Products
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Updates</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="12" class="pb-0">
                            <h4>Fertilizer products you will produce/import</h4>
                          </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>

                        <v-row class="container-items">
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="pt-0"
                          >
                            <v-checkbox
                              v-model="ex4"
                              label="Ammonium Sulfate"
                              value="1"
                              hide-details
                            ></v-checkbox>
                            <v-checkbox
                              v-model="ex4"
                              label="Urea Super Granulated "
                              value="2"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                      >
                        Update Products
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="8" sm="12" class="py-0 text-left">
                <v-btn class="grey">Update Products</v-btn>
              </v-col>
              
            </v-row>
            <div class="text--primary mt-5">
              <v-data-table
                dense
                :headers="headers"
                :items="desserts"
                item-key="name"
                class="elevation-1"
              ></v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
//import AppCard1Starter from '@/components/ui/application/appcard/AppCard1Starter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1
    //AppCard2Starter,
    /*AppCard1Starter ,
    AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {
      dialog: false,
      desserts: [
        {
          name: 'Processing Fee',
          fees: 'N300,000.00'
        },
        {
          name: 'Registration Fee',
          fees: '	N1,000,000.00'
        },
        {
          name: 'Renewal Fee',
          fees: 'N500,000.00'
        },
        {
          name: 'Late Application for renewal fee',
          fees: 'N150,000.00'
        },
        {
          name: 'Inspection fee per metric ton',
          fees: 'N100.00'
        },
        {
          name: 'Late submission of tonnage report fee',
          fees: 'N300,000.00'
        }
      ],
      checkboxValue: 0,
      warehouseOption: 0,
      warehouseOptions:[
        {
          'id': 1,
          'name': 'Gwagwalada'
        },
        {
          'id': 2,
          'name': 'Owner'
        },
        {
          'id': 3,
          'name': 'Rent'
        }
      ],
      headers: [
        {
          text: 'Fertilizer Products',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Product Group', value: 'fees' },
        { text: 'Last Post Date', value: 'fees' }
      ],
      stateId: 0,
      lgaId: 0,
      allstates:[
        {
          'id': 1,
          'name': 'Abia'
        },
        {
          'id': 2,
          'name': 'Adamawa'
        },
        {
          'id': 3,
          'name': 'Akwa Ibom'
        },
        {
          'id': 4,
          'name': 'Anambra'
        },
        {
          'id': 5,
          'name': 'Bauchi'
        },
        {
          'id': 6,
          'name': 'Benue'
        },
        {
          'id': 7,
          'name': 'Borno'
        },
        {
          'id': 8,
          'name': 'Bayelsa'
        },
        {
          'id': 9,
          'name': 'Cross River'
        },
        {
          'id': 10,
          'name': 'Delta'
        },
        {
          'id': 11,
          'name': 'Ebonyi'
        },
        {
          'id': 12,
          'name': 'Edo'
        },
        {
          'id': 13,
          'name': 'Ekiti'
        },
        {
          'id': 14,
          'name': 'Enugu'
        },
        {
          'id': 15,
          'name': 'FCT'
        },
        {
          'id': 16,
          'name': 'Gombe'
        },
        {
          'id': 17,
          'name': 'Imo'
        },
        {
          'id': 18,
          'name': 'Jigawa'
        },
        {
          'id': 19,
          'name': 'Kebbi'
        },
        {
          'id': 20,
          'name': 'Kaduna'
        },
        {
          'id': 21,
          'name': 'Kogi'
        },
        {
          'id': 22,
          'name': 'Kano'
        },
        {
          'id': 23,
          'name': 'Katsina'
        },
        {
          'id': 24,
          'name': 'Kwara'
        },
        {
          'id': 25,
          'name': 'Lagos'
        },
        {
          'id': 26,
          'name': 'Niger'
        },
        {
          'id': 27,
          'name': 'Nasarawa'
        },
        {
          'id': 28,
          'name': 'Ondo'
        },
        {
          'id': 29,
          'name': 'Ogun'
        },
        {
          'id': 30,
          'name': 'Osun'
        },
        {
          'id': 31,
          'name': 'Oyo'
        },
        {
          'id': 32,
          'name': 'Plateau'
        },
        {
          'id': 33,
          'name': 'Rivers'
        },
        {
          'id': 34,
          'name': 'Sokoto'
        },
        {
          'id': 35,
          'name': 'Taraba'
        },
        {
          'id': 36,
          'name': 'Yobe'
        },
        {
          'id': 37,
          'name': 'Zamfara'
        }
      ],
      statelgas:[],
      alllga:[
        {
          'id': 1,
          'name': 'Aba North',
          'state_id': 1
        },
        {
          'id': 2,
          'name': 'Aba South',
          'state_id': 1
        },
        {
          'id': 3,
          'name': 'Arochukwu',
          'state_id': 1
        },
        {
          'id': 4,
          'name': 'Bende',
          'state_id': 1
        },
        {
          'id': 5,
          'name': 'Ikwuano',
          'state_id': 1
        },
        {
          'id': 6,
          'name': 'Isiala Ngwa North',
          'state_id': 1
        },
        {
          'id': 7,
          'name': 'Isiala Ngwa South',
          'state_id': 1
        },
        {
          'id': 8,
          'name': 'Isuikwuato',
          'state_id': 1
        },
        {
          'id': 9,
          'name': 'Obingwa',
          'state_id': 1
        },
        {
          'id': 10,
          'name': 'Ohafia',
          'state_id': 1
        },
        {
          'id': 11,
          'name': 'Osisioma Ngwa',
          'state_id': 1
        },
        {
          'id': 12,
          'name': 'Ugwunagbo',
          'state_id': 1
        },
        {
          'id': 13,
          'name': 'Ukwa East',
          'state_id': 1
        },
        {
          'id': 14,
          'name': 'Ukwa  West',
          'state_id': 1
        },
        {
          'id': 15,
          'name': 'Umuahia North',
          'state_id': 1
        },
        {
          'id': 16,
          'name': 'Umuahia  South',
          'state_id': 1
        },
        {
          'id': 17,
          'name': 'Umu - Nneochi',
          'state_id': 1
        },
        {
          'id': 18,
          'name': 'Demsa',
          'state_id': 2
        },
        {
          'id': 19,
          'name': 'Fufore',
          'state_id': 2
        },
        {
          'id': 20,
          'name': 'Ganye',
          'state_id': 2
        },
        {
          'id': 21,
          'name': 'Girei',
          'state_id': 2
        },
        {
          'id': 22,
          'name': 'Gombi',
          'state_id': 2
        },
        {
          'id': 23,
          'name': 'Guyuk',
          'state_id': 2
        },
        {
          'id': 24,
          'name': 'Hong',
          'state_id': 2
        },
        {
          'id': 25,
          'name': 'Jada',
          'state_id': 2
        },
        {
          'id': 26,
          'name': 'Lamurde',
          'state_id': 2
        },
        {
          'id': 27,
          'name': 'Madagali',
          'state_id': 2
        },
        {
          'id': 28,
          'name': 'Maiha',
          'state_id': 2
        },
        {
          'id': 29,
          'name': 'Mayo - Belwa',
          'state_id': 2
        },
        {
          'id': 30,
          'name': 'Michika',
          'state_id': 2
        },
        {
          'id': 31,
          'name': 'Mubi North',
          'state_id': 2
        },
        {
          'id': 32,
          'name': 'Mubi South',
          'state_id': 2
        },
        {
          'id': 33,
          'name': 'Numan',
          'state_id': 2
        },
        {
          'id': 34,
          'name': 'Shelleng',
          'state_id': 2
        },
        {
          'id': 35,
          'name': 'Song',
          'state_id': 2
        },
        {
          'id': 36,
          'name': 'Toungo',
          'state_id': 2
        },
        {
          'id': 37,
          'name': 'Yola North',
          'state_id': 2
        },
        {
          'id': 38,
          'name': 'Yola South',
          'state_id': 2
        },
        {
          'id': 39,
          'name': 'Abak',
          'state_id': 3
        },
        {
          'id': 40,
          'name': 'Eastern Obolo',
          'state_id': 3
        },
        {
          'id': 41,
          'name': 'Eket',
          'state_id': 3
        },
        {
          'id': 42,
          'name': 'Esit Eket (Uquo)',
          'state_id': 3
        },
        {
          'id': 43,
          'name': 'Essien Udim',
          'state_id': 3
        },
        {
          'id': 44,
          'name': 'Etim Ekpo',
          'state_id': 3
        },
        {
          'id': 45,
          'name': 'Etinan',
          'state_id': 3
        },
        {
          'id': 46,
          'name': 'Ibeno',
          'state_id': 3
        },
        {
          'id': 47,
          'name': 'Ibesikpo Asutan',
          'state_id': 3
        },
        {
          'id': 48,
          'name': 'Ibiono Ibom',
          'state_id': 3
        },
        {
          'id': 49,
          'name': 'Ika',
          'state_id': 3
        },
        {
          'id': 50,
          'name': 'Ikono',
          'state_id': 3
        },
        {
          'id': 51,
          'name': 'Ikot Abasi',
          'state_id': 3
        },
        {
          'id': 52,
          'name': 'Ikot Ekpene',
          'state_id': 3
        },
        {
          'id': 53,
          'name': 'Ini',
          'state_id': 3
        },
        {
          'id': 54,
          'name': 'Itu',
          'state_id': 3
        },
        {
          'id': 55,
          'name': 'Mbo',
          'state_id': 3
        },
        {
          'id': 56,
          'name': 'Mkpat Enin',
          'state_id': 3
        },
        {
          'id': 57,
          'name': 'Nsit Atai',
          'state_id': 3
        },
        {
          'id': 58,
          'name': 'Nsit Ibom',
          'state_id': 3
        },
        {
          'id': 59,
          'name': 'Nsit Ubium',
          'state_id': 3
        },
        {
          'id': 60,
          'name': 'Obot Akara',
          'state_id': 3
        },
        {
          'id': 61,
          'name': 'Okobo',
          'state_id': 3
        },
        {
          'id': 62,
          'name': 'Onna',
          'state_id': 3
        },
        {
          'id': 63,
          'name': 'Oron',
          'state_id': 3
        },
        {
          'id': 64,
          'name': 'Oruk Anam',
          'state_id': 3
        },
        {
          'id': 65,
          'name': 'Udung Uko',
          'state_id': 3
        },
        {
          'id': 66,
          'name': 'Ukanafun',
          'state_id': 3
        },
        {
          'id': 67,
          'name': 'Uruan',
          'state_id': 3
        },
        {
          'id': 68,
          'name': 'Urue Offong/Oruko',
          'state_id': 3
        },
        {
          'id': 69,
          'name': 'Uyo',
          'state_id': 3
        },
        {
          'id': 70,
          'name': 'Aguata',
          'state_id': 4
        },
        {
          'id': 71,
          'name': 'Ayamelum',
          'state_id': 4
        },
        {
          'id': 72,
          'name': 'Anambra East',
          'state_id': 4
        },
        {
          'id': 73,
          'name': 'Anambra West',
          'state_id': 4
        },
        {
          'id': 74,
          'name': 'Anaocha',
          'state_id': 4
        },
        {
          'id': 75,
          'name': 'Awka North',
          'state_id': 4
        },
        {
          'id': 76,
          'name': 'Awka South',
          'state_id': 4
        },
        {
          'id': 77,
          'name': 'Dunukofia',
          'state_id': 4
        },
        {
          'id': 78,
          'name': 'Ekwusigo',
          'state_id': 4
        },
        {
          'id': 79,
          'name': 'Idemili North',
          'state_id': 4
        },
        {
          'id': 80,
          'name': 'Idemili-South',
          'state_id': 4
        },
        {
          'id': 81,
          'name': 'Ihiala',
          'state_id': 4
        },
        {
          'id': 82,
          'name': 'Njikoka',
          'state_id': 4
        },
        {
          'id': 83,
          'name': 'Nnewi North',
          'state_id': 4
        },
        {
          'id': 84,
          'name': 'Nnewi South',
          'state_id': 4
        },
        {
          'id': 85,
          'name': 'Ogbaru',
          'state_id': 4
        },
        {
          'id': 86,
          'name': 'Onitsha-North',
          'state_id': 4
        },
        {
          'id': 87,
          'name': 'Onitsha -South',
          'state_id': 4
        },
        {
          'id': 88,
          'name': 'Orumba North',
          'state_id': 4
        },
        {
          'id': 89,
          'name': 'Orumba  South',
          'state_id': 4
        },
        {
          'id': 90,
          'name': 'Oyi',
          'state_id': 4
        },
        {
          'id': 91,
          'name': 'Alkaleri',
          'state_id': 5
        },
        {
          'id': 92,
          'name': 'Bauchi',
          'state_id': 5
        },
        {
          'id': 93,
          'name': 'Bogoro',
          'state_id': 5
        },
        {
          'id': 94,
          'name': 'Dambam',
          'state_id': 5
        },
        {
          'id': 95,
          'name': 'Darazo',
          'state_id': 5
        },
        {
          'id': 96,
          'name': 'Dass',
          'state_id': 5
        },
        {
          'id': 97,
          'name': 'Gamawa',
          'state_id': 5
        },
        {
          'id': 98,
          'name': 'Ganjuwa',
          'state_id': 5
        },
        {
          'id': 99,
          'name': 'Giade',
          'state_id': 5
        },
        {
          'id': 100,
          'name': 'Itas/Gadau',
          'state_id': 5
        },
        {
          'id': 101,
          'name': 'Jama are',
          'state_id': 5
        },
        {
          'id': 102,
          'name': 'Katagum',
          'state_id': 5
        },
        {
          'id': 103,
          'name': 'Kirfi',
          'state_id': 5
        },
        {
          'id': 104,
          'name': 'Misau',
          'state_id': 5
        },
        {
          'id': 105,
          'name': 'Ningi',
          'state_id': 5
        },
        {
          'id': 106,
          'name': 'Shira',
          'state_id': 5
        },
        {
          'id': 107,
          'name': 'Tafawa Balewa',
          'state_id': 5
        },
        {
          'id': 108,
          'name': 'Toro',
          'state_id': 5
        },
        {
          'id': 109,
          'name': 'Warji',
          'state_id': 5
        },
        {
          'id': 110,
          'name': 'Zaki',
          'state_id': 5
        },
        {
          'id': 111,
          'name': 'Brass',
          'state_id': 8
        },
        {
          'id': 112,
          'name': 'Ekeremor',
          'state_id': 8
        },
        {
          'id': 113,
          'name': 'Kolokuma/Opokuma',
          'state_id': 8
        },
        {
          'id': 114,
          'name': 'Nembe',
          'state_id': 8
        },
        {
          'id': 115,
          'name': 'Ogbia',
          'state_id': 8
        },
        {
          'id': 116,
          'name': 'Sagbama',
          'state_id': 8
        },
        {
          'id': 117,
          'name': 'Southern Ijaw',
          'state_id': 8
        },
        {
          'id': 118,
          'name': 'Yenagoa',
          'state_id': 8
        },
        {
          'id': 119,
          'name': 'Ado',
          'state_id': 6
        },
        {
          'id': 120,
          'name': 'Agatu',
          'state_id': 6
        },
        {
          'id': 121,
          'name': 'Apa',
          'state_id': 6
        },
        {
          'id': 122,
          'name': 'Buruku',
          'state_id': 6
        },
        {
          'id': 123,
          'name': 'Gboko',
          'state_id': 6
        },
        {
          'id': 124,
          'name': 'Guma',
          'state_id': 6
        },
        {
          'id': 125,
          'name': 'Gwer East',
          'state_id': 6
        },
        {
          'id': 126,
          'name': 'Gwer West',
          'state_id': 6
        },
        {
          'id': 127,
          'name': 'Katsina-Ala',
          'state_id': 6
        },
        {
          'id': 128,
          'name': 'Konshisha',
          'state_id': 6
        },
        {
          'id': 129,
          'name': 'Kwande',
          'state_id': 6
        },
        {
          'id': 130,
          'name': 'Logo',
          'state_id': 6
        },
        {
          'id': 131,
          'name': 'Makurdi',
          'state_id': 6
        },
        {
          'id': 132,
          'name': 'Obi',
          'state_id': 6
        },
        {
          'id': 133,
          'name': 'Ogbadibo',
          'state_id': 6
        },
        {
          'id': 134,
          'name': 'Oju',
          'state_id': 6
        },
        {
          'id': 135,
          'name': 'Ohimini',
          'state_id': 6
        },
        {
          'id': 136,
          'name': 'Okpokwu',
          'state_id': 6
        },
        {
          'id': 137,
          'name': 'Otukpo',
          'state_id': 6
        },
        {
          'id': 138,
          'name': 'Tarka',
          'state_id': 6
        },
        {
          'id': 139,
          'name': 'Ukum',
          'state_id': 6
        },
        {
          'id': 140,
          'name': 'Ushongo',
          'state_id': 6
        },
        {
          'id': 141,
          'name': 'Vandeikya',
          'state_id': 6
        },
        {
          'id': 142,
          'name': 'Abadam',
          'state_id': 7
        },
        {
          'id': 143,
          'name': 'Askira / Uba',
          'state_id': 7
        },
        {
          'id': 144,
          'name': 'Bama',
          'state_id': 7
        },
        {
          'id': 145,
          'name': 'Bayo',
          'state_id': 7
        },
        {
          'id': 146,
          'name': 'Biu',
          'state_id': 7
        },
        {
          'id': 147,
          'name': 'Chibok',
          'state_id': 7
        },
        {
          'id': 148,
          'name': 'Damboa',
          'state_id': 7
        },
        {
          'id': 149,
          'name': 'Dikwa',
          'state_id': 7
        },
        {
          'id': 150,
          'name': 'Gubio',
          'state_id': 7
        },
        {
          'id': 151,
          'name': 'Guzamala',
          'state_id': 7
        },
        {
          'id': 152,
          'name': 'Gwoza',
          'state_id': 7
        },
        {
          'id': 153,
          'name': 'Hawul',
          'state_id': 7
        },
        {
          'id': 154,
          'name': 'Jere',
          'state_id': 7
        },
        {
          'id': 155,
          'name': 'Kaga',
          'state_id': 7
        },
        {
          'id': 156,
          'name': 'Kala Balge',
          'state_id': 7
        },
        {
          'id': 157,
          'name': 'Konduga',
          'state_id': 7
        },
        {
          'id': 158,
          'name': 'Kukawa',
          'state_id': 7
        },
        {
          'id': 159,
          'name': 'Kwaya / Kusar',
          'state_id': 7
        },
        {
          'id': 160,
          'name': 'Mafa',
          'state_id': 7
        },
        {
          'id': 161,
          'name': 'Magumeri',
          'state_id': 7
        },
        {
          'id': 162,
          'name': 'Maiduguri M. C.',
          'state_id': 7
        },
        {
          'id': 163,
          'name': 'Marte',
          'state_id': 7
        },
        {
          'id': 164,
          'name': 'Mobbar',
          'state_id': 7
        },
        {
          'id': 165,
          'name': 'Monguno',
          'state_id': 7
        },
        {
          'id': 166,
          'name': 'Ngala',
          'state_id': 7
        },
        {
          'id': 167,
          'name': 'Nganzai',
          'state_id': 7
        },
        {
          'id': 168,
          'name': 'Shani',
          'state_id': 7
        },
        {
          'id': 169,
          'name': 'Abi',
          'state_id': 9
        },
        {
          'id': 170,
          'name': 'Akamkpa',
          'state_id': 9
        },
        {
          'id': 171,
          'name': 'Akpabuyo',
          'state_id': 9
        },
        {
          'id': 172,
          'name': 'Bakassi',
          'state_id': 9
        },
        {
          'id': 173,
          'name': 'Bekwarra',
          'state_id': 9
        },
        {
          'id': 174,
          'name': 'Biase',
          'state_id': 9
        },
        {
          'id': 175,
          'name': 'Boki',
          'state_id': 9
        },
        {
          'id': 176,
          'name': 'Calabar Municipality',
          'state_id': 9
        },
        {
          'id': 177,
          'name': 'Calabar South',
          'state_id': 9
        },
        {
          'id': 178,
          'name': 'Etung',
          'state_id': 9
        },
        {
          'id': 179,
          'name': 'Ikom',
          'state_id': 9
        },
        {
          'id': 180,
          'name': 'Obanliku',
          'state_id': 9
        },
        {
          'id': 181,
          'name': 'Obubra',
          'state_id': 9
        },
        {
          'id': 182,
          'name': 'Obudu',
          'state_id': 9
        },
        {
          'id': 183,
          'name': 'Odukpani',
          'state_id': 9
        },
        {
          'id': 184,
          'name': 'Ogoja',
          'state_id': 9
        },
        {
          'id': 185,
          'name': 'Yakurr',
          'state_id': 9
        },
        {
          'id': 186,
          'name': 'Yala',
          'state_id': 9
        },
        {
          'id': 187,
          'name': 'Aniocha North',
          'state_id': 10
        },
        {
          'id': 188,
          'name': 'Aniocha - South',
          'state_id': 10
        },
        {
          'id': 189,
          'name': 'Bomadi',
          'state_id': 10
        },
        {
          'id': 190,
          'name': 'Burutu',
          'state_id': 10
        },
        {
          'id': 191,
          'name': 'Ethiope  East',
          'state_id': 10
        },
        {
          'id': 192,
          'name': 'Ethiope  West',
          'state_id': 10
        },
        {
          'id': 193,
          'name': 'Ika North- East',
          'state_id': 10
        },
        {
          'id': 194,
          'name': 'Ika - South',
          'state_id': 10
        },
        {
          'id': 195,
          'name': 'Isoko North',
          'state_id': 10
        },
        {
          'id': 196,
          'name': 'Isoko South',
          'state_id': 10
        },
        {
          'id': 197,
          'name': 'Ndokwa East',
          'state_id': 10
        },
        {
          'id': 198,
          'name': 'Ndokwa West',
          'state_id': 10
        },
        {
          'id': 199,
          'name': 'Okpe',
          'state_id': 10
        },
        {
          'id': 200,
          'name': 'Oshimili - North',
          'state_id': 10
        },
        {
          'id': 201,
          'name': 'Oshimili - South',
          'state_id': 10
        },
        {
          'id': 202,
          'name': 'Patani',
          'state_id': 10
        },
        {
          'id': 203,
          'name': 'Sapele',
          'state_id': 10
        },
        {
          'id': 204,
          'name': 'Udu',
          'state_id': 10
        },
        {
          'id': 205,
          'name': 'Ughelli North',
          'state_id': 10
        },
        {
          'id': 206,
          'name': 'Ughelli South',
          'state_id': 10
        },
        {
          'id': 207,
          'name': 'Ukwuani',
          'state_id': 10
        },
        {
          'id': 208,
          'name': 'Uvwie',
          'state_id': 10
        },
        {
          'id': 209,
          'name': 'Warri  North',
          'state_id': 10
        },
        {
          'id': 210,
          'name': 'Warri South',
          'state_id': 10
        },
        {
          'id': 211,
          'name': 'Warri South  West',
          'state_id': 10
        },
        {
          'id': 212,
          'name': 'Abakaliki',
          'state_id': 11
        },
        {
          'id': 213,
          'name': 'Afikpo North',
          'state_id': 11
        },
        {
          'id': 214,
          'name': 'Afikpo  South',
          'state_id': 11
        },
        {
          'id': 215,
          'name': 'Ebonyi',
          'state_id': 11
        },
        {
          'id': 216,
          'name': 'Ezza North',
          'state_id': 11
        },
        {
          'id': 217,
          'name': 'Ezza South',
          'state_id': 11
        },
        {
          'id': 218,
          'name': 'Ikwo',
          'state_id': 11
        },
        {
          'id': 219,
          'name': 'Ishielu',
          'state_id': 11
        },
        {
          'id': 220,
          'name': 'Ivo',
          'state_id': 11
        },
        {
          'id': 221,
          'name': 'Izzi',
          'state_id': 11
        },
        {
          'id': 222,
          'name': 'Ohaozara',
          'state_id': 11
        },
        {
          'id': 223,
          'name': 'Ohaukwu',
          'state_id': 11
        },
        {
          'id': 224,
          'name': 'Onicha',
          'state_id': 11
        },
        {
          'id': 225,
          'name': 'Akoko Edo',
          'state_id': 12
        },
        {
          'id': 226,
          'name': 'Egor',
          'state_id': 12
        },
        {
          'id': 227,
          'name': 'Esan Central',
          'state_id': 12
        },
        {
          'id': 228,
          'name': 'Esan North East',
          'state_id': 12
        },
        {
          'id': 229,
          'name': 'Esan South East',
          'state_id': 12
        },
        {
          'id': 230,
          'name': 'Esan West',
          'state_id': 12
        },
        {
          'id': 231,
          'name': 'Etsako Central',
          'state_id': 12
        },
        {
          'id': 232,
          'name': 'Etsako East',
          'state_id': 12
        },
        {
          'id': 233,
          'name': 'Etsako  West',
          'state_id': 12
        },
        {
          'id': 234,
          'name': 'Igueben',
          'state_id': 12
        },
        {
          'id': 235,
          'name': 'Ikpoba/Okha',
          'state_id': 12
        },
        {
          'id': 236,
          'name': 'Oredo',
          'state_id': 12
        },
        {
          'id': 237,
          'name': 'Orhionmwon',
          'state_id': 12
        },
        {
          'id': 238,
          'name': 'Ovia North East',
          'state_id': 12
        },
        {
          'id': 239,
          'name': 'Ovia South West',
          'state_id': 12
        },
        {
          'id': 240,
          'name': 'Owan East',
          'state_id': 12
        },
        {
          'id': 241,
          'name': 'Owan West',
          'state_id': 12
        },
        {
          'id': 242,
          'name': 'Uhunmwode',
          'state_id': 12
        },
        {
          'id': 243,
          'name': 'Ado Ekiti',
          'state_id': 13
        },
        {
          'id': 244,
          'name': 'Efon',
          'state_id': 13
        },
        {
          'id': 245,
          'name': 'Ekiti East',
          'state_id': 13
        },
        {
          'id': 246,
          'name': 'Ekiti West',
          'state_id': 13
        },
        {
          'id': 247,
          'name': 'Ekiti South West',
          'state_id': 13
        },
        {
          'id': 248,
          'name': 'Emure',
          'state_id': 13
        },
        {
          'id': 249,
          'name': 'Gbonyin',
          'state_id': 13
        },
        {
          'id': 250,
          'name': 'Ido / Osi',
          'state_id': 13
        },
        {
          'id': 251,
          'name': 'Ijero',
          'state_id': 13
        },
        {
          'id': 252,
          'name': 'Ikare',
          'state_id': 13
        },
        {
          'id': 253,
          'name': 'Ikole',
          'state_id': 13
        },
        {
          'id': 254,
          'name': 'Ilejemeje',
          'state_id': 13
        },
        {
          'id': 255,
          'name': 'Irepodun / Ifelodun',
          'state_id': 13
        },
        {
          'id': 256,
          'name': 'Ise / Orun',
          'state_id': 13
        },
        {
          'id': 257,
          'name': 'Moba',
          'state_id': 13
        },
        {
          'id': 258,
          'name': 'Oye',
          'state_id': 13
        },
        {
          'id': 259,
          'name': 'Aninri',
          'state_id': 14
        },
        {
          'id': 260,
          'name': 'Awgu',
          'state_id': 14
        },
        {
          'id': 261,
          'name': 'Enugu East',
          'state_id': 14
        },
        {
          'id': 262,
          'name': 'Enugu North',
          'state_id': 14
        },
        {
          'id': 263,
          'name': 'Enugu South',
          'state_id': 14
        },
        {
          'id': 264,
          'name': 'Ezeagu',
          'state_id': 14
        },
        {
          'id': 265,
          'name': 'Igbo Etiti',
          'state_id': 14
        },
        {
          'id': 266,
          'name': 'Igbo Eze North',
          'state_id': 14
        },
        {
          'id': 267,
          'name': 'Igbo Eze South',
          'state_id': 14
        },
        {
          'id': 268,
          'name': 'Isi Uzo',
          'state_id': 14
        },
        {
          'id': 269,
          'name': 'Nkanu East',
          'state_id': 14
        },
        {
          'id': 270,
          'name': 'Nkanu West',
          'state_id': 14
        },
        {
          'id': 271,
          'name': 'Nsukka',
          'state_id': 14
        },
        {
          'id': 272,
          'name': 'Oji-River',
          'state_id': 14
        },
        {
          'id': 273,
          'name': 'Udenu',
          'state_id': 14
        },
        {
          'id': 274,
          'name': 'Udi',
          'state_id': 14
        },
        {
          'id': 275,
          'name': 'Uzo-Uwani',
          'state_id': 14
        },
        {
          'id': 276,
          'name': 'Abaji',
          'state_id': 15
        },
        {
          'id': 277,
          'name': 'Bwari',
          'state_id': 15
        },
        {
          'id': 278,
          'name': 'Gwagwalada',
          'state_id': 15
        },
        {
          'id': 279,
          'name': 'Kuje',
          'state_id': 15
        },
        {
          'id': 280,
          'name': 'Kwali',
          'state_id': 15
        },
        {
          'id': 281,
          'name': 'Municipal',
          'state_id': 15
        },
        {
          'id': 282,
          'name': 'Akko',
          'state_id': 16
        },
        {
          'id': 283,
          'name': 'Balanga',
          'state_id': 16
        },
        {
          'id': 284,
          'name': 'Billiri',
          'state_id': 16
        },
        {
          'id': 285,
          'name': 'Dukku',
          'state_id': 16
        },
        {
          'id': 286,
          'name': 'Funakaye',
          'state_id': 16
        },
        {
          'id': 287,
          'name': 'Gombe',
          'state_id': 16
        },
        {
          'id': 288,
          'name': 'Kaltungo',
          'state_id': 16
        },
        {
          'id': 289,
          'name': 'Kwami',
          'state_id': 16
        },
        {
          'id': 290,
          'name': 'Nafada',
          'state_id': 16
        },
        {
          'id': 291,
          'name': 'Shongom',
          'state_id': 16
        },
        {
          'id': 292,
          'name': 'Yalmaltu/ Deba',
          'state_id': 16
        },
        {
          'id': 293,
          'name': 'Aboh Mbaise',
          'state_id': 17
        },
        {
          'id': 294,
          'name': 'Ahiazu Mbaise',
          'state_id': 17
        },
        {
          'id': 295,
          'name': 'Ehime Mbano',
          'state_id': 17
        },
        {
          'id': 296,
          'name': 'Ezinihitte Mbaise',
          'state_id': 17
        },
        {
          'id': 297,
          'name': 'Ideato North',
          'state_id': 17
        },
        {
          'id': 298,
          'name': 'Ideato South',
          'state_id': 17
        },
        {
          'id': 299,
          'name': 'Ihitte/Uboma (Isinweke)',
          'state_id': 17
        },
        {
          'id': 300,
          'name': 'Ikeduru (Iho)',
          'state_id': 17
        },
        {
          'id': 301,
          'name': 'Isiala Mbano (Umuelemai)',
          'state_id': 17
        },
        {
          'id': 302,
          'name': 'Isu (Umundugba)',
          'state_id': 17
        },
        {
          'id': 303,
          'name': 'Mbaitoli (Nwaorieubi)',
          'state_id': 17
        },
        {
          'id': 304,
          'name': 'Ngor Okpala (Umuneke)',
          'state_id': 17
        },
        {
          'id': 305,
          'name': 'Njaba (Nnenasa)',
          'state_id': 17
        },
        {
          'id': 306,
          'name': 'Nkwerre',
          'state_id': 17
        },
        {
          'id': 307,
          'name': 'Nwangele (Onu-Nwangele Amaigbo)',
          'state_id': 17
        },
        {
          'id': 308,
          'name': 'Obowo (Otoko)',
          'state_id': 17
        },
        {
          'id': 309,
          'name': 'Oguta (Oguta)',
          'state_id': 17
        },
        {
          'id': 310,
          'name': 'Ohaji/Egbema (Mmahu-Egbema)',
          'state_id': 17
        },
        {
          'id': 311,
          'name': 'Okigwe  (Okigwe)',
          'state_id': 17
        },
        {
          'id': 312,
          'name': 'Onuimo (Okwe)',
          'state_id': 17
        },
        {
          'id': 313,
          'name': 'Orlu',
          'state_id': 17
        },
        {
          'id': 314,
          'name': 'Orsu (Awo Idemili)',
          'state_id': 17
        },
        {
          'id': 315,
          'name': 'Oru-East',
          'state_id': 17
        },
        {
          'id': 316,
          'name': 'Oru West (Mgbidi)',
          'state_id': 17
        },
        {
          'id': 317,
          'name': 'Owerri Municipal',
          'state_id': 17
        },
        {
          'id': 318,
          'name': 'Owerri North (Orie Uratta)',
          'state_id': 17
        },
        {
          'id': 319,
          'name': 'Owerri West (Umuguma)',
          'state_id': 17
        },
        {
          'id': 320,
          'name': 'Auyo',
          'state_id': 18
        },
        {
          'id': 321,
          'name': 'Babura',
          'state_id': 18
        },
        {
          'id': 322,
          'name': 'Birnin Kudu',
          'state_id': 18
        },
        {
          'id': 323,
          'name': 'Birniwa',
          'state_id': 18
        },
        {
          'id': 324,
          'name': 'Buji',
          'state_id': 18
        },
        {
          'id': 325,
          'name': 'Dutse',
          'state_id': 18
        },
        {
          'id': 326,
          'name': 'Garki',
          'state_id': 18
        },
        {
          'id': 327,
          'name': 'Gagarawa',
          'state_id': 18
        },
        {
          'id': 328,
          'name': 'Gumel',
          'state_id': 18
        },
        {
          'id': 329,
          'name': 'Guri',
          'state_id': 18
        },
        {
          'id': 330,
          'name': 'Gwaram',
          'state_id': 18
        },
        {
          'id': 331,
          'name': 'Gwiwa',
          'state_id': 18
        },
        {
          'id': 332,
          'name': 'Hadejia',
          'state_id': 18
        },
        {
          'id': 333,
          'name': 'Jahun',
          'state_id': 18
        },
        {
          'id': 334,
          'name': 'Kafin Hausa',
          'state_id': 18
        },
        {
          'id': 335,
          'name': 'Kaugama',
          'state_id': 18
        },
        {
          'id': 336,
          'name': 'Kazaure',
          'state_id': 18
        },
        {
          'id': 337,
          'name': 'Kirika Samma',
          'state_id': 18
        },
        {
          'id': 338,
          'name': 'Kiyawa',
          'state_id': 18
        },
        {
          'id': 339,
          'name': 'Maigatari',
          'state_id': 18
        },
        {
          'id': 340,
          'name': 'Malam Madori',
          'state_id': 18
        },
        {
          'id': 341,
          'name': 'Miga',
          'state_id': 18
        },
        {
          'id': 342,
          'name': 'Ringim',
          'state_id': 18
        },
        {
          'id': 343,
          'name': 'Roni',
          'state_id': 18
        },
        {
          'id': 344,
          'name': 'Sule-Tankarkar',
          'state_id': 18
        },
        {
          'id': 345,
          'name': 'Taura',
          'state_id': 18
        },
        {
          'id': 346,
          'name': 'Yankwashi',
          'state_id': 18
        },
        {
          'id': 347,
          'name': 'Birnin Gwari',
          'state_id': 20
        },
        {
          'id': 348,
          'name': 'Chikun',
          'state_id': 20
        },
        {
          'id': 349,
          'name': 'Giwa',
          'state_id': 20
        },
        {
          'id': 350,
          'name': 'Igabi',
          'state_id': 20
        },
        {
          'id': 351,
          'name': 'Ikara',
          'state_id': 20
        },
        {
          'id': 352,
          'name': 'Jaba',
          'state_id': 20
        },
        {
          'id': 353,
          'name': 'Jema a',
          'state_id': 20
        },
        {
          'id': 354,
          'name': 'Kachia',
          'state_id': 20
        },
        {
          'id': 355,
          'name': 'Kaduna North',
          'state_id': 20
        },
        {
          'id': 356,
          'name': 'Kaduna South',
          'state_id': 20
        },
        {
          'id': 357,
          'name': 'Kagarko',
          'state_id': 20
        },
        {
          'id': 358,
          'name': 'Kajuru',
          'state_id': 20
        },
        {
          'id': 359,
          'name': 'Kaura',
          'state_id': 20
        },
        {
          'id': 360,
          'name': 'Kauru',
          'state_id': 20
        },
        {
          'id': 361,
          'name': 'Kubau',
          'state_id': 20
        },
        {
          'id': 362,
          'name': 'Kudan',
          'state_id': 20
        },
        {
          'id': 363,
          'name': 'Lere',
          'state_id': 20
        },
        {
          'id': 364,
          'name': 'Makarfi',
          'state_id': 20
        },
        {
          'id': 365,
          'name': 'Sabon Gari',
          'state_id': 20
        },
        {
          'id': 366,
          'name': 'Sanga',
          'state_id': 20
        },
        {
          'id': 367,
          'name': 'Soba',
          'state_id': 20
        },
        {
          'id': 368,
          'name': 'Zangon Kataf',
          'state_id': 20
        },
        {
          'id': 369,
          'name': 'Zaria',
          'state_id': 20
        },
        {
          'id': 370,
          'name': 'Ajingi',
          'state_id': 22
        },
        {
          'id': 371,
          'name': 'Albasu',
          'state_id': 22
        },
        {
          'id': 372,
          'name': 'Bagwai',
          'state_id': 22
        },
        {
          'id': 373,
          'name': 'Bebeji',
          'state_id': 22
        },
        {
          'id': 374,
          'name': 'Bichi',
          'state_id': 22
        },
        {
          'id': 375,
          'name': 'Bunkure',
          'state_id': 22
        },
        {
          'id': 376,
          'name': 'Dala',
          'state_id': 22
        },
        {
          'id': 377,
          'name': 'Danbata',
          'state_id': 22
        },
        {
          'id': 378,
          'name': 'Dawaki Kudu',
          'state_id': 22
        },
        {
          'id': 379,
          'name': 'Dawaki Tofa',
          'state_id': 22
        },
        {
          'id': 380,
          'name': 'Doguwa',
          'state_id': 22
        },
        {
          'id': 381,
          'name': 'Fagge',
          'state_id': 22
        },
        {
          'id': 382,
          'name': 'Gabasawa',
          'state_id': 22
        },
        {
          'id': 383,
          'name': 'Garko',
          'state_id': 22
        },
        {
          'id': 384,
          'name': 'Garun Malam',
          'state_id': 22
        },
        {
          'id': 385,
          'name': 'Gaya',
          'state_id': 22
        },
        {
          'id': 386,
          'name': 'Gezawa',
          'state_id': 22
        },
        {
          'id': 387,
          'name': 'Gwale',
          'state_id': 22
        },
        {
          'id': 388,
          'name': 'Gwarzo',
          'state_id': 22
        },
        {
          'id': 389,
          'name': 'Kabo',
          'state_id': 22
        },
        {
          'id': 390,
          'name': 'Kano Municipal',
          'state_id': 22
        },
        {
          'id': 391,
          'name': 'Karaye',
          'state_id': 22
        },
        {
          'id': 392,
          'name': 'Kibiya',
          'state_id': 22
        },
        {
          'id': 393,
          'name': 'Kiru',
          'state_id': 22
        },
        {
          'id': 394,
          'name': 'Kumbotso',
          'state_id': 22
        },
        {
          'id': 395,
          'name': 'Kunchi',
          'state_id': 22
        },
        {
          'id': 396,
          'name': 'Kura',
          'state_id': 22
        },
        {
          'id': 397,
          'name': 'Madobi',
          'state_id': 22
        },
        {
          'id': 398,
          'name': 'Makoda',
          'state_id': 22
        },
        {
          'id': 399,
          'name': 'Minjibir',
          'state_id': 22
        },
        {
          'id': 400,
          'name': 'Nasarawa',
          'state_id': 22
        },
        {
          'id': 401,
          'name': 'Rano',
          'state_id': 22
        },
        {
          'id': 402,
          'name': 'Rimin Gado',
          'state_id': 22
        },
        {
          'id': 403,
          'name': 'Rogo',
          'state_id': 22
        },
        {
          'id': 404,
          'name': 'Shanono',
          'state_id': 22
        },
        {
          'id': 405,
          'name': 'Sumaila',
          'state_id': 22
        },
        {
          'id': 406,
          'name': 'Takali',
          'state_id': 22
        },
        {
          'id': 407,
          'name': 'Tarauni',
          'state_id': 22
        },
        {
          'id': 408,
          'name': 'Tofa',
          'state_id': 22
        },
        {
          'id': 409,
          'name': 'Tsanyawa',
          'state_id': 22
        },
        {
          'id': 410,
          'name': 'Tudun Wada',
          'state_id': 22
        },
        {
          'id': 411,
          'name': 'Ungogo',
          'state_id': 22
        },
        {
          'id': 412,
          'name': 'Warawa',
          'state_id': 22
        },
        {
          'id': 413,
          'name': 'Wudil',
          'state_id': 22
        },
        {
          'id': 414,
          'name': 'Bakori',
          'state_id': 23
        },
        {
          'id': 415,
          'name': 'Batagarawa',
          'state_id': 23
        },
        {
          'id': 416,
          'name': 'Batsari',
          'state_id': 23
        },
        {
          'id': 417,
          'name': 'Baure',
          'state_id': 23
        },
        {
          'id': 418,
          'name': 'Bindawa',
          'state_id': 23
        },
        {
          'id': 419,
          'name': 'Charanchi',
          'state_id': 23
        },
        {
          'id': 420,
          'name': 'Dandume',
          'state_id': 23
        },
        {
          'id': 421,
          'name': 'Danja',
          'state_id': 23
        },
        {
          'id': 422,
          'name': 'Dan Musa',
          'state_id': 23
        },
        {
          'id': 423,
          'name': 'Daura',
          'state_id': 23
        },
        {
          'id': 424,
          'name': 'Dutsi',
          'state_id': 23
        },
        {
          'id': 425,
          'name': 'Dutsin-Ma',
          'state_id': 23
        },
        {
          'id': 426,
          'name': 'Faskari',
          'state_id': 23
        },
        {
          'id': 427,
          'name': 'Funtua',
          'state_id': 23
        },
        {
          'id': 428,
          'name': 'Ingawa',
          'state_id': 23
        },
        {
          'id': 429,
          'name': 'Jibia',
          'state_id': 23
        },
        {
          'id': 430,
          'name': 'Kafur',
          'state_id': 23
        },
        {
          'id': 431,
          'name': 'Kaita',
          'state_id': 23
        },
        {
          'id': 432,
          'name': 'Kankara',
          'state_id': 23
        },
        {
          'id': 433,
          'name': 'Kankia',
          'state_id': 23
        },
        {
          'id': 434,
          'name': 'Katsina',
          'state_id': 23
        },
        {
          'id': 435,
          'name': 'Kurfi',
          'state_id': 23
        },
        {
          'id': 436,
          'name': 'Kusada',
          'state_id': 23
        },
        {
          'id': 437,
          'name': 'Mai adua',
          'state_id': 23
        },
        {
          'id': 438,
          'name': 'Malufashi',
          'state_id': 23
        },
        {
          'id': 439,
          'name': 'Mani',
          'state_id': 23
        },
        {
          'id': 440,
          'name': 'Mashi',
          'state_id': 23
        },
        {
          'id': 441,
          'name': 'Matazu',
          'state_id': 23
        },
        {
          'id': 442,
          'name': 'Musawa',
          'state_id': 23
        },
        {
          'id': 443,
          'name': 'Rimi',
          'state_id': 23
        },
        {
          'id': 444,
          'name': 'Sabuwa',
          'state_id': 23
        },
        {
          'id': 445,
          'name': 'Safana',
          'state_id': 23
        },
        {
          'id': 446,
          'name': 'Sandamu',
          'state_id': 23
        },
        {
          'id': 447,
          'name': 'Zango',
          'state_id': 23
        },
        {
          'id': 448,
          'name': 'Aliero',
          'state_id': 19
        },
        {
          'id': 449,
          'name': 'Arewa',
          'state_id': 19
        },
        {
          'id': 450,
          'name': 'Argungu',
          'state_id': 19
        },
        {
          'id': 451,
          'name': 'Augie',
          'state_id': 19
        },
        {
          'id': 452,
          'name': 'Bagudo',
          'state_id': 19
        },
        {
          'id': 453,
          'name': 'Birnin Kebbi',
          'state_id': 19
        },
        {
          'id': 454,
          'name': 'Bunza',
          'state_id': 19
        },
        {
          'id': 455,
          'name': 'Dandi',
          'state_id': 19
        },
        {
          'id': 456,
          'name': 'Fakai',
          'state_id': 19
        },
        {
          'id': 457,
          'name': 'Gwandu',
          'state_id': 19
        },
        {
          'id': 458,
          'name': 'Jega',
          'state_id': 19
        },
        {
          'id': 459,
          'name': 'Kalgo',
          'state_id': 19
        },
        {
          'id': 460,
          'name': 'Koko/Besse',
          'state_id': 19
        },
        {
          'id': 461,
          'name': 'Maiyama',
          'state_id': 19
        },
        {
          'id': 462,
          'name': 'Ngaski',
          'state_id': 19
        },
        {
          'id': 463,
          'name': 'Sakaba',
          'state_id': 19
        },
        {
          'id': 464,
          'name': 'Shanga',
          'state_id': 19
        },
        {
          'id': 465,
          'name': 'Suru',
          'state_id': 19
        },
        {
          'id': 466,
          'name': 'Wasagu/Danko',
          'state_id': 19
        },
        {
          'id': 467,
          'name': 'Yauri',
          'state_id': 19
        },
        {
          'id': 468,
          'name': 'Zuru',
          'state_id': 19
        },
        {
          'id': 469,
          'name': 'Adavi',
          'state_id': 21
        },
        {
          'id': 470,
          'name': 'Ajaokuta',
          'state_id': 21
        },
        {
          'id': 471,
          'name': 'Ankpa',
          'state_id': 21
        },
        {
          'id': 472,
          'name': 'Bassa',
          'state_id': 21
        },
        {
          'id': 473,
          'name': 'Dekina',
          'state_id': 21
        },
        {
          'id': 474,
          'name': 'Ibaji',
          'state_id': 21
        },
        {
          'id': 475,
          'name': 'Idah',
          'state_id': 21
        },
        {
          'id': 476,
          'name': 'Igalamela/Odolu',
          'state_id': 21
        },
        {
          'id': 477,
          'name': 'Ijumu',
          'state_id': 21
        },
        {
          'id': 478,
          'name': 'Kabba/Bunu',
          'state_id': 21
        },
        {
          'id': 479,
          'name': 'Kogi . K. K.',
          'state_id': 21
        },
        {
          'id': 480,
          'name': 'Lokoja',
          'state_id': 21
        },
        {
          'id': 481,
          'name': 'Mopa Moro',
          'state_id': 21
        },
        {
          'id': 482,
          'name': 'Ofu',
          'state_id': 21
        },
        {
          'id': 483,
          'name': 'Ogori Mangogo',
          'state_id': 21
        },
        {
          'id': 484,
          'name': 'Okehi',
          'state_id': 21
        },
        {
          'id': 485,
          'name': 'Okene',
          'state_id': 21
        },
        {
          'id': 486,
          'name': 'Olamaboro',
          'state_id': 21
        },
        {
          'id': 487,
          'name': 'Omala',
          'state_id': 21
        },
        {
          'id': 488,
          'name': 'Yagba East',
          'state_id': 21
        },
        {
          'id': 489,
          'name': 'Yagba West',
          'state_id': 21
        },
        {
          'id': 490,
          'name': 'Asa',
          'state_id': 24
        },
        {
          'id': 491,
          'name': 'Baruten',
          'state_id': 24
        },
        {
          'id': 492,
          'name': 'Edu',
          'state_id': 24
        },
        {
          'id': 493,
          'name': 'Ekiti',
          'state_id': 24
        },
        {
          'id': 494,
          'name': 'Ifelodun',
          'state_id': 24
        },
        {
          'id': 495,
          'name': 'Ilorin East',
          'state_id': 24
        },
        {
          'id': 496,
          'name': 'Ilorin-South',
          'state_id': 24
        },
        {
          'id': 497,
          'name': 'Ilorin-West',
          'state_id': 24
        },
        {
          'id': 498,
          'name': 'Irepodun',
          'state_id': 24
        },
        {
          'id': 499,
          'name': 'Isin',
          'state_id': 24
        },
        {
          'id': 500,
          'name': 'Kaiama',
          'state_id': 24
        },
        {
          'id': 501,
          'name': 'Moro',
          'state_id': 24
        },
        {
          'id': 502,
          'name': 'Offa',
          'state_id': 24
        },
        {
          'id': 503,
          'name': 'Oke - Ero',
          'state_id': 24
        },
        {
          'id': 504,
          'name': 'Oyun',
          'state_id': 24
        },
        {
          'id': 505,
          'name': 'Patigi',
          'state_id': 24
        },
        {
          'id': 506,
          'name': 'Agege',
          'state_id': 25
        },
        {
          'id': 507,
          'name': 'Ajeromi/Ifelodun',
          'state_id': 25
        },
        {
          'id': 508,
          'name': 'Alimosho',
          'state_id': 25
        },
        {
          'id': 509,
          'name': 'Amuwo-Odofin',
          'state_id': 25
        },
        {
          'id': 510,
          'name': 'Apapa',
          'state_id': 25
        },
        {
          'id': 511,
          'name': 'Badagry',
          'state_id': 25
        },
        {
          'id': 512,
          'name': 'Epe',
          'state_id': 25
        },
        {
          'id': 513,
          'name': 'Eti-Osa',
          'state_id': 25
        },
        {
          'id': 514,
          'name': 'Ibeju/Lekki',
          'state_id': 25
        },
        {
          'id': 515,
          'name': 'Ifako-Ijaye',
          'state_id': 25
        },
        {
          'id': 516,
          'name': 'Ikeja',
          'state_id': 25
        },
        {
          'id': 517,
          'name': 'Ikorodu',
          'state_id': 25
        },
        {
          'id': 518,
          'name': 'Kosofe',
          'state_id': 25
        },
        {
          'id': 519,
          'name': 'Lagos Island',
          'state_id': 25
        },
        {
          'id': 520,
          'name': 'Lagos Mainland',
          'state_id': 25
        },
        {
          'id': 521,
          'name': 'Mushin',
          'state_id': 25
        },
        {
          'id': 522,
          'name': 'Ojo',
          'state_id': 25
        },
        {
          'id': 523,
          'name': 'Oshodi/Isolo',
          'state_id': 25
        },
        {
          'id': 524,
          'name': 'Somolu',
          'state_id': 25
        },
        {
          'id': 525,
          'name': 'Surulere',
          'state_id': 25
        },
        {
          'id': 526,
          'name': 'Akwanga',
          'state_id': 27
        },
        {
          'id': 527,
          'name': 'Awe',
          'state_id': 27
        },
        {
          'id': 528,
          'name': 'Doma',
          'state_id': 27
        },
        {
          'id': 529,
          'name': 'Karu',
          'state_id': 27
        },
        {
          'id': 530,
          'name': 'Keana',
          'state_id': 27
        },
        {
          'id': 531,
          'name': 'Keffi',
          'state_id': 27
        },
        {
          'id': 532,
          'name': 'Kokona',
          'state_id': 27
        },
        {
          'id': 533,
          'name': 'Lafia',
          'state_id': 27
        },
        {
          'id': 534,
          'name': 'Nasarawa Eggon',
          'state_id': 27
        },
        {
          'id': 535,
          'name': 'Toto',
          'state_id': 27
        },
        {
          'id': 536,
          'name': 'Wamba',
          'state_id': 27
        },
        {
          'id': 537,
          'name': 'Agaie',
          'state_id': 26
        },
        {
          'id': 538,
          'name': 'Agwara',
          'state_id': 26
        },
        {
          'id': 539,
          'name': 'Bida',
          'state_id': 26
        },
        {
          'id': 540,
          'name': 'Borgu',
          'state_id': 26
        },
        {
          'id': 541,
          'name': 'Bosso',
          'state_id': 26
        },
        {
          'id': 542,
          'name': 'Chanchaga',
          'state_id': 26
        },
        {
          'id': 543,
          'name': 'Edatti',
          'state_id': 26
        },
        {
          'id': 544,
          'name': 'Gbako',
          'state_id': 26
        },
        {
          'id': 545,
          'name': 'Gurara',
          'state_id': 26
        },
        {
          'id': 546,
          'name': 'Katcha',
          'state_id': 26
        },
        {
          'id': 547,
          'name': 'Kontagora',
          'state_id': 26
        },
        {
          'id': 548,
          'name': 'Lapai',
          'state_id': 26
        },
        {
          'id': 549,
          'name': 'Lavun',
          'state_id': 26
        },
        {
          'id': 550,
          'name': 'Magama',
          'state_id': 26
        },
        {
          'id': 551,
          'name': 'Mariga',
          'state_id': 26
        },
        {
          'id': 552,
          'name': 'Mashegu',
          'state_id': 26
        },
        {
          'id': 553,
          'name': 'Mokwa',
          'state_id': 26
        },
        {
          'id': 554,
          'name': 'Munya',
          'state_id': 26
        },
        {
          'id': 555,
          'name': 'Paikoro',
          'state_id': 26
        },
        {
          'id': 556,
          'name': 'Rafi',
          'state_id': 26
        },
        {
          'id': 557,
          'name': 'Rijau',
          'state_id': 26
        },
        {
          'id': 558,
          'name': 'Shiroro',
          'state_id': 26
        },
        {
          'id': 559,
          'name': 'Suleja',
          'state_id': 26
        },
        {
          'id': 560,
          'name': 'Tafa',
          'state_id': 26
        },
        {
          'id': 561,
          'name': 'Wushishi',
          'state_id': 26
        },
        {
          'id': 562,
          'name': 'Abeokuta North',
          'state_id': 29
        },
        {
          'id': 563,
          'name': 'Abeokuta South',
          'state_id': 29
        },
        {
          'id': 564,
          'name': 'Ado Odo-Ota',
          'state_id': 29
        },
        {
          'id': 565,
          'name': 'Egbado North',
          'state_id': 29
        },
        {
          'id': 566,
          'name': 'Egbado South',
          'state_id': 29
        },
        {
          'id': 567,
          'name': 'Ewekoro',
          'state_id': 29
        },
        {
          'id': 568,
          'name': 'Ifo',
          'state_id': 29
        },
        {
          'id': 569,
          'name': 'Ijebu East',
          'state_id': 29
        },
        {
          'id': 570,
          'name': 'Ijebu North',
          'state_id': 29
        },
        {
          'id': 571,
          'name': 'Ijebu North East',
          'state_id': 29
        },
        {
          'id': 572,
          'name': 'Ijebu Ode',
          'state_id': 29
        },
        {
          'id': 573,
          'name': 'Ikenne',
          'state_id': 29
        },
        {
          'id': 574,
          'name': 'Imeko/Afon',
          'state_id': 29
        },
        {
          'id': 575,
          'name': 'Ipokia',
          'state_id': 29
        },
        {
          'id': 576,
          'name': 'Obafemi/Owode',
          'state_id': 29
        },
        {
          'id': 577,
          'name': 'Odeda',
          'state_id': 29
        },
        {
          'id': 578,
          'name': 'Odogbolu',
          'state_id': 29
        },
        {
          'id': 579,
          'name': 'Ogun Water Side',
          'state_id': 29
        },
        {
          'id': 580,
          'name': 'Remo North',
          'state_id': 29
        },
        {
          'id': 581,
          'name': 'Sagamu',
          'state_id': 29
        },
        {
          'id': 582,
          'name': 'Atakumosa East',
          'state_id': 30
        },
        {
          'id': 583,
          'name': 'Atakumosa West',
          'state_id': 30
        },
        {
          'id': 584,
          'name': 'Ayedaade',
          'state_id': 30
        },
        {
          'id': 585,
          'name': 'Ayedire',
          'state_id': 30
        },
        {
          'id': 586,
          'name': 'Boluwaduro',
          'state_id': 30
        },
        {
          'id': 587,
          'name': 'Boripe',
          'state_id': 30
        },
        {
          'id': 588,
          'name': 'Ede North',
          'state_id': 30
        },
        {
          'id': 589,
          'name': 'Ede South',
          'state_id': 30
        },
        {
          'id': 590,
          'name': 'Egbedore',
          'state_id': 30
        },
        {
          'id': 591,
          'name': 'Ejigbo',
          'state_id': 30
        },
        {
          'id': 592,
          'name': 'Ife Central',
          'state_id': 30
        },
        {
          'id': 593,
          'name': 'Ifedayo',
          'state_id': 30
        },
        {
          'id': 594,
          'name': 'Ife East',
          'state_id': 30
        },
        {
          'id': 595,
          'name': 'Ife North',
          'state_id': 30
        },
        {
          'id': 596,
          'name': 'Ife South',
          'state_id': 30
        },
        {
          'id': 597,
          'name': 'Ila',
          'state_id': 30
        },
        {
          'id': 598,
          'name': 'Ilesa East',
          'state_id': 30
        },
        {
          'id': 599,
          'name': 'Ilesa West',
          'state_id': 30
        },
        {
          'id': 600,
          'name': 'Irewole',
          'state_id': 30
        },
        {
          'id': 601,
          'name': 'Isokan',
          'state_id': 30
        },
        {
          'id': 602,
          'name': 'Iwo',
          'state_id': 30
        },
        {
          'id': 603,
          'name': 'Obokun',
          'state_id': 30
        },
        {
          'id': 604,
          'name': 'Odo-Otin',
          'state_id': 30
        },
        {
          'id': 605,
          'name': 'Ola-Oluwa',
          'state_id': 30
        },
        {
          'id': 606,
          'name': 'Olorunda',
          'state_id': 30
        },
        {
          'id': 607,
          'name': 'Oriade',
          'state_id': 30
        },
        {
          'id': 608,
          'name': 'Orolu',
          'state_id': 30
        },
        {
          'id': 609,
          'name': 'Osogbo',
          'state_id': 30
        },
        {
          'id': 610,
          'name': 'Afijio',
          'state_id': 31
        },
        {
          'id': 611,
          'name': 'Akinyele',
          'state_id': 31
        },
        {
          'id': 612,
          'name': 'Atiba',
          'state_id': 31
        },
        {
          'id': 613,
          'name': 'Atisbo',
          'state_id': 31
        },
        {
          'id': 614,
          'name': 'Egbeda',
          'state_id': 31
        },
        {
          'id': 615,
          'name': 'Ibadan North',
          'state_id': 31
        },
        {
          'id': 616,
          'name': 'Ibadan North East',
          'state_id': 31
        },
        {
          'id': 617,
          'name': 'Ibadan North West',
          'state_id': 31
        },
        {
          'id': 618,
          'name': 'Ibadan South-East',
          'state_id': 31
        },
        {
          'id': 619,
          'name': 'Ibadan South West',
          'state_id': 31
        },
        {
          'id': 620,
          'name': 'Ibarapa Central',
          'state_id': 31
        },
        {
          'id': 621,
          'name': 'Ibarapa East',
          'state_id': 31
        },
        {
          'id': 622,
          'name': 'Ibarapa North',
          'state_id': 31
        },
        {
          'id': 623,
          'name': 'Ido',
          'state_id': 31
        },
        {
          'id': 624,
          'name': 'Irepo',
          'state_id': 31
        },
        {
          'id': 625,
          'name': 'Iseyin',
          'state_id': 31
        },
        {
          'id': 626,
          'name': 'Itesiwaju',
          'state_id': 31
        },
        {
          'id': 627,
          'name': 'Iwajowa',
          'state_id': 31
        },
        {
          'id': 628,
          'name': 'Kajola',
          'state_id': 31
        },
        {
          'id': 629,
          'name': 'Lagelu',
          'state_id': 31
        },
        {
          'id': 630,
          'name': 'Ogbomoso North',
          'state_id': 31
        },
        {
          'id': 631,
          'name': 'Ogbomoso South',
          'state_id': 31
        },
        {
          'id': 632,
          'name': 'Ogo-Oluwa',
          'state_id': 31
        },
        {
          'id': 633,
          'name': 'Olorunsogo',
          'state_id': 31
        },
        {
          'id': 634,
          'name': 'Oluyole',
          'state_id': 31
        },
        {
          'id': 635,
          'name': 'Ona-Ara',
          'state_id': 31
        },
        {
          'id': 636,
          'name': 'Oorelope',
          'state_id': 31
        },
        {
          'id': 637,
          'name': 'Ori Ire',
          'state_id': 31
        },
        {
          'id': 638,
          'name': 'Oyo East',
          'state_id': 31
        },
        {
          'id': 639,
          'name': 'Oyo West',
          'state_id': 31
        },
        {
          'id': 640,
          'name': 'Saki East',
          'state_id': 31
        },
        {
          'id': 641,
          'name': 'Saki West',
          'state_id': 31
        },
        {
          'id': 642,
          'name': 'Barikin Ladi',
          'state_id': 32
        },
        {
          'id': 643,
          'name': 'Bokkos',
          'state_id': 32
        },
        {
          'id': 644,
          'name': 'Jos East',
          'state_id': 32
        },
        {
          'id': 645,
          'name': 'Jos North',
          'state_id': 32
        },
        {
          'id': 646,
          'name': 'Jos South',
          'state_id': 32
        },
        {
          'id': 647,
          'name': 'Kanam',
          'state_id': 32
        },
        {
          'id': 648,
          'name': 'Kanke',
          'state_id': 32
        },
        {
          'id': 649,
          'name': 'Langtang North',
          'state_id': 32
        },
        {
          'id': 650,
          'name': 'Langtang South',
          'state_id': 32
        },
        {
          'id': 651,
          'name': 'Mangu',
          'state_id': 32
        },
        {
          'id': 652,
          'name': 'Mikang',
          'state_id': 32
        },
        {
          'id': 653,
          'name': 'Pankshin',
          'state_id': 32
        },
        {
          'id': 654,
          'name': 'Qua an Pan',
          'state_id': 32
        },
        {
          'id': 655,
          'name': 'Riyom',
          'state_id': 32
        },
        {
          'id': 656,
          'name': 'Shendam',
          'state_id': 32
        },
        {
          'id': 657,
          'name': 'Wase',
          'state_id': 32
        },
        {
          'id': 658,
          'name': 'Abua-Odual',
          'state_id': 33
        },
        {
          'id': 659,
          'name': 'Ahoada East',
          'state_id': 33
        },
        {
          'id': 660,
          'name': 'Ahoada West',
          'state_id': 33
        },
        {
          'id': 661,
          'name': 'Akuku Toru',
          'state_id': 33
        },
        {
          'id': 662,
          'name': 'Andoni',
          'state_id': 33
        },
        {
          'id': 663,
          'name': 'Asari-Toru',
          'state_id': 33
        },
        {
          'id': 664,
          'name': 'Bonny',
          'state_id': 33
        },
        {
          'id': 665,
          'name': 'Degema',
          'state_id': 33
        },
        {
          'id': 666,
          'name': 'Eleme',
          'state_id': 33
        },
        {
          'id': 667,
          'name': 'Emohua',
          'state_id': 33
        },
        {
          'id': 668,
          'name': 'Etche',
          'state_id': 33
        },
        {
          'id': 669,
          'name': 'Gokana',
          'state_id': 33
        },
        {
          'id': 670,
          'name': 'Ikwerre',
          'state_id': 33
        },
        {
          'id': 671,
          'name': 'Khana',
          'state_id': 33
        },
        {
          'id': 672,
          'name': 'Obio/Akpor',
          'state_id': 33
        },
        {
          'id': 673,
          'name': 'Ogba/Egbema/Ndoni',
          'state_id': 33
        },
        {
          'id': 674,
          'name': 'Ogu/Bolo',
          'state_id': 33
        },
        {
          'id': 675,
          'name': 'Okrika',
          'state_id': 33
        },
        {
          'id': 676,
          'name': 'Omuma',
          'state_id': 33
        },
        {
          'id': 677,
          'name': 'Opobo/Nekoro',
          'state_id': 33
        },
        {
          'id': 678,
          'name': 'Oyigbo',
          'state_id': 33
        },
        {
          'id': 679,
          'name': 'Port Harcourt',
          'state_id': 33
        },
        {
          'id': 680,
          'name': 'Tai',
          'state_id': 33
        },
        {
          'id': 681,
          'name': 'Binji',
          'state_id': 34
        },
        {
          'id': 682,
          'name': 'Bodinga',
          'state_id': 34
        },
        {
          'id': 683,
          'name': 'Dange/Shuni',
          'state_id': 34
        },
        {
          'id': 684,
          'name': 'Gada',
          'state_id': 34
        },
        {
          'id': 685,
          'name': 'Goronyo',
          'state_id': 34
        },
        {
          'id': 686,
          'name': 'Gudu',
          'state_id': 34
        },
        {
          'id': 687,
          'name': 'Gwadabawa',
          'state_id': 34
        },
        {
          'id': 688,
          'name': 'Illela',
          'state_id': 34
        },
        {
          'id': 689,
          'name': 'Isa',
          'state_id': 34
        },
        {
          'id': 690,
          'name': 'Kware',
          'state_id': 34
        },
        {
          'id': 691,
          'name': 'Kebbe',
          'state_id': 34
        },
        {
          'id': 692,
          'name': 'Rabah',
          'state_id': 34
        },
        {
          'id': 693,
          'name': 'S/Birni',
          'state_id': 34
        },
        {
          'id': 694,
          'name': 'Shagari',
          'state_id': 34
        },
        {
          'id': 695,
          'name': 'Silame',
          'state_id': 34
        },
        {
          'id': 696,
          'name': 'Sokoto North',
          'state_id': 34
        },
        {
          'id': 697,
          'name': 'Sokoto South',
          'state_id': 34
        },
        {
          'id': 698,
          'name': 'Tambuwal',
          'state_id': 34
        },
        {
          'id': 699,
          'name': 'Tangaza',
          'state_id': 34
        },
        {
          'id': 700,
          'name': 'Tureta',
          'state_id': 34
        },
        {
          'id': 701,
          'name': 'Wamakko',
          'state_id': 34
        },
        {
          'id': 702,
          'name': 'Wurno',
          'state_id': 34
        },
        {
          'id': 703,
          'name': 'Yabo',
          'state_id': 34
        },
        {
          'id': 704,
          'name': 'Ardo - Kola',
          'state_id': 35
        },
        {
          'id': 705,
          'name': 'Bali',
          'state_id': 35
        },
        {
          'id': 706,
          'name': 'Donga',
          'state_id': 35
        },
        {
          'id': 707,
          'name': 'Gashaka',
          'state_id': 35
        },
        {
          'id': 708,
          'name': 'Gassol',
          'state_id': 35
        },
        {
          'id': 709,
          'name': 'Ibi',
          'state_id': 35
        },
        {
          'id': 710,
          'name': 'Jalingo',
          'state_id': 35
        },
        {
          'id': 711,
          'name': 'Karim-Lamido',
          'state_id': 35
        },
        {
          'id': 712,
          'name': 'Kurmi',
          'state_id': 35
        },
        {
          'id': 713,
          'name': 'Lau',
          'state_id': 35
        },
        {
          'id': 714,
          'name': 'Sardauna',
          'state_id': 35
        },
        {
          'id': 715,
          'name': 'Takum',
          'state_id': 35
        },
        {
          'id': 716,
          'name': 'Ussa',
          'state_id': 35
        },
        {
          'id': 717,
          'name': 'Wukari',
          'state_id': 35
        },
        {
          'id': 718,
          'name': 'Yorro',
          'state_id': 35
        },
        {
          'id': 719,
          'name': 'Zing',
          'state_id': 35
        },
        {
          'id': 720,
          'name': 'Bade',
          'state_id': 36
        },
        {
          'id': 721,
          'name': 'Bursari',
          'state_id': 36
        },
        {
          'id': 722,
          'name': 'Damaturu',
          'state_id': 36
        },
        {
          'id': 723,
          'name': 'Fika',
          'state_id': 36
        },
        {
          'id': 724,
          'name': 'Fune',
          'state_id': 36
        },
        {
          'id': 725,
          'name': 'Geidam',
          'state_id': 36
        },
        {
          'id': 726,
          'name': 'Gujba',
          'state_id': 36
        },
        {
          'id': 727,
          'name': 'Gulani',
          'state_id': 36
        },
        {
          'id': 728,
          'name': 'Jakusko',
          'state_id': 36
        },
        {
          'id': 729,
          'name': 'Karasawa',
          'state_id': 36
        },
        {
          'id': 730,
          'name': 'Machina',
          'state_id': 36
        },
        {
          'id': 731,
          'name': 'Nangere',
          'state_id': 36
        },
        {
          'id': 732,
          'name': 'Nguru',
          'state_id': 36
        },
        {
          'id': 733,
          'name': 'Potiskum',
          'state_id': 36
        },
        {
          'id': 734,
          'name': 'Tarmuwa',
          'state_id': 36
        },
        {
          'id': 735,
          'name': 'Yunusari',
          'state_id': 36
        },
        {
          'id': 736,
          'name': 'Yusufari',
          'state_id': 36
        },
        {
          'id': 737,
          'name': 'Anka',
          'state_id': 37
        },
        {
          'id': 738,
          'name': 'Bakura',
          'state_id': 37
        },
        {
          'id': 739,
          'name': 'Birnin Magaji',
          'state_id': 37
        },
        {
          'id': 740,
          'name': 'Bukkuyum',
          'state_id': 37
        },
        {
          'id': 741,
          'name': 'Bungudu',
          'state_id': 37
        },
        {
          'id': 742,
          'name': 'Gummi',
          'state_id': 37
        },
        {
          'id': 743,
          'name': 'Gusau',
          'state_id': 37
        },
        {
          'id': 744,
          'name': 'Kaura Namoda',
          'state_id': 37
        },
        {
          'id': 745,
          'name': 'Maradun',
          'state_id': 37
        },
        {
          'id': 746,
          'name': 'Maru',
          'state_id': 37
        },
        {
          'id': 747,
          'name': 'Shinkafi',
          'state_id': 37
        },
        {
          'id': 748,
          'name': 'Talata Mafara',
          'state_id': 37
        },
        {
          'id': 749,
          'name': 'Tsafe',
          'state_id': 37
        },
        {
          'id': 750,
          'name': 'Zurmi',
          'state_id': 37
        },
        {
          'id': 751,
          'name': 'Bassa',
          'state_id': 32
        },
        {
          'id': 752,
          'name': 'Irepodun',
          'state_id': 30
        },
        {
          'id': 753,
          'name': 'Nasarawa',
          'state_id': 27
        },
        {
          'id': 754,
          'name': 'Ifelodun',
          'state_id': 30
        },
        {
          'id': 755,
          'name': 'Surulere',
          'state_id': 31
        },
        {
          'id': 756,
          'name': 'Obi',
          'state_id': 27
        },
        {
          'id': 757,
          'name': 'Akoko North East',
          'state_id': 28
        },
        {
          'id': 758,
          'name': 'Akoko North West',
          'state_id': 28
        },
        {
          'id': 759,
          'name': 'Akoko South East',
          'state_id': 28
        },
        {
          'id': 760,
          'name': 'Akoko South West',
          'state_id': 28
        },
        {
          'id': 761,
          'name': 'Akure North',
          'state_id': 28
        },
        {
          'id': 762,
          'name': 'Akure South',
          'state_id': 28
        },
        {
          'id': 763,
          'name': 'Ese Odo',
          'state_id': 28
        },
        {
          'id': 764,
          'name': 'Idanre',
          'state_id': 28
        },
        {
          'id': 765,
          'name': 'Ifedore',
          'state_id': 28
        },
        {
          'id': 766,
          'name': 'Ilage',
          'state_id': 28
        },
        {
          'id': 767,
          'name': 'Ileoluju / Okeigbo',
          'state_id': 28
        },
        {
          'id': 768,
          'name': 'Irele',
          'state_id': 28
        },
        {
          'id': 769,
          'name': 'Odigbo',
          'state_id': 28
        },
        {
          'id': 770,
          'name': 'Okitipupa',
          'state_id': 28
        },
        {
          'id': 771,
          'name': 'Ondo East',
          'state_id': 28
        },
        {
          'id': 772,
          'name': 'Ondo West',
          'state_id': 28
        },
        {
          'id': 773,
          'name': 'Ose',
          'state_id': 28
        },
        {
          'id': 774,
          'name': 'Owo',
          'state_id': 28
        }
      ]
    }
  },
  watch: {
    stateId: {
      handler(newvalue) {
        console.log('item changed', newvalue)
        //////console.log('state id is ', this.stateId)
        this.statelgas = this.alllga.filter((lga) => lga.state_id === this.stateId.id)
        //////console.log('all the lga is ', this.statelgas)
      },
      immediate: true
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height{
  min-height: 300px !important;
}

.container-items{
  font-size: 12px !important;
}

</style>
