<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4 pd-3">Transaction History</h1>
      <p>
        This page is for view and verification of transactions generated by ledger, 
        if you want to verify older RRR; enter the RRR below and click on the <b>VERIFY RRR STATUS</b>.
        <br/>If you want to view older transaction click view transaction history</p>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-alert
      border="left"
      color="green lighten-1"
      dense
      text
      type="info"
    >
      For verification REMITA transaction generated without ledger Id
    </v-alert>
    <v-row>
      <v-col cols="12" md="7">
        <v-text-field
          label="RRR"
          placeholder="Enter old RRR"
          hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="5">
        <v-btn color="primary" large @click="verifyOldRRR()">
          Verify RRR status
        </v-btn> 
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table 
            :headers="headers"
            :items="tableData"
            sort-by="postDate,credit,debit"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="isInvoiceGenOK(item)"
                outlined
                color="green"
                @click="generateInvoice(item)"
              >
                Generate Invoice
              </v-btn>
              <div v-else>
                <v-btn
                  v-if="isInvoiceViewOK(item)"
                  outlined
                  @click="viewInvoice(item)"
                >
                  View Invoice
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'Thus table has no record!',
      credit:0,
      balance:0,
      debit:0,

      snackbar: false,
      snackbartext: '',

      headers: [
        { text: 'Description', value: 'description' },
        { text: 'Transaction Id', value: 'transaction_id' },
        { text: 'RRR', value: 'RRR' },
        { text: 'Payment Status', value: 'payment_status' },
        { text: 'Amount', value: 'amount' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      tableData: []
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
  },
  mounted() {},
  methods: {
    getTableData: function () {
      const url = httpServ.getPaymentUrl('/payment/ledger/getTransactionDetails')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          result.data.forEach((item) => {
            this.credit += item.credit
            this.debit += item.debit
            this.balance = Math.abs(this.credit - this.debit)
            //console.log(item.payment_status)
          })
          this.tableData = result.data
        } 
      })
      this.tableLoading = false
    },
    generateInvoice: function(item) {
      const postData = this.prePostData

      postData.ledger_id = item.id
      postData.company_name = item.company_name
      postData.email = item.email
      postData.phone = item.phone
      postData.description = item.transaction_type

      //this.updateProfileBtnIsActive = true
      //this.updateProfileBtnText = 'Submiting ...'
      
      const url = httpServ.getPaymentUrl('/payment/remita/createInvoice')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          item.RRR = result.data.RRR
          item.transactionId = result.data.transaction_id
          item.status = 'new'
          this.snackbartext = 'Your invoice have generated successfully',
          this.snackbar = true
          //const { id } = item

          this.$router.push({ name: 'remitaInvoice', params:  {
            obj: { item }
          } })
        } else {
          this.snackbartext = 'Error: ' + result.message + '! You need to try again'
          this.snackbar = true
        }
      })
    },
    viewInvoice: function(item) {
      item.status = 'old'
      this.$router.push({ name: 'remitaInvoice', params:  {
        obj: { item }
      } })
    },
    isInvoiceGenOK: function(item) {
      if (item.transaction_id === null && item.credit > 0) {
        return true
      }  else {
        return false
      }
    },
    isInvoiceViewOK: function(item) {

      if (item.payment_status === 1) {
        return true
      }  else {
        return false
      }
    },
    verifyOldRRR: function() {
      const url = httpServ.getPaymentUrl('/payment/remita/createInvoice')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          item.RRR = result.data.RRR
          item.transactionId = result.data.transaction_id
          item.status = 'new'
          this.snackbartext = 'Your invoice have generated successfully',
          this.snackbar = true
          //const { id } = item

          this.$router.push({ name: 'remitaInvoice', params:  {
            obj: { item }
          } })
        } else {
          this.snackbartext = 'Error: ' + result.message + '! You need to try again'
          this.snackbar = true
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
