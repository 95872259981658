<template>
  <div>
    <ImportationReportFiling />
  </div>
</template>

<script>
import ImportationReportFiling from '@/nfmp_components/ImportationReportFiling.vue'
export default {
  components: {
    ImportationReportFiling
  }
}
</script>