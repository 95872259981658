<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Legacy Transaction History</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table 
            :headers="headers"
            :items="tableData"
            sort-by="postDate,credit,debit"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'Thus table has no record!',
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext: '',

      headers: [
        { text: 'Description', value: 'description' },
        { text: 'Transaction Id', value: 'transaction_id' },
        { text: 'Invoice Number', value: 'invoice_number' },
        { text: 'Payment Channel', value: 'ppayment_channel' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'payment_status' }
      ],
      tableData: []
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
  },
  mounted() {},
  methods: {
    getTableData: function () {
      const url = httpServ.getPaymentUrl('/payment/ledger/getLegacyTransactionDetails')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          result.data.forEach((item) => {
          })
          this.tableData = result.data
        } 
      })
      this.tableLoading = false
    }
  }
}
</script>

<style scoped>
</style>
