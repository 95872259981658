export default {

  makeRequest: function ( ourUrl, params = {}, method = '') {
    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const options = {
      method
    }
    
    if ( method === 'GETwithparams' ) {
      ourUrl += '?' + ( new URLSearchParams( params ) ).toString()
      options.method = 'GET'
    }

    if ( method === 'POST' ) {
      options.body = JSON.stringify( params )
      options.headers = { 'Content-Type': 'application/json' }
      options.method = 'POST'
    }

    if ( method === 'POSTWITHJWT' ) {
      options.body = JSON.stringify( params )
      options.headers = { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${params.jwtToken}`
      }
      options.method = 'POST'
    }

    if ( method === 'GETWITHJWT' ) {

      const queryString = Object.keys(params).map((key) => key + '=' + encodeURIComponent(params[key])).join('&')
      
      ourUrl = ourUrl + queryString
      
      const encoder = new TextEncoder()
      const encodedParams = encoder.encode(JSON.stringify(params)).toString('base64')

      //options.body = JSON.stringify( params )
      options.headers = { 
        'Authorization': `Bearer ${params.jwtToken}`
      }
      options.method = 'GET'
    }
    //return fetch( url, options ).then( response => response.json() )
    console.log('our options are ', options)
    
    return fetch(ourUrl, options)
      .then(async (response) => {

        const data = await response.json()

        switch (response.status) {
        case 200:
          console.log('OK')
          break
        case 400:
          console.log('general error!')
          break
        case 401:
          console.log('Wrong login!')
          break
        case 403:
          console.log('Forbidden/!')
          break
        case 404:
          console.log('Method not found!')
          break
        case 409:
          console.log('Data conflict/data exist!')
          break
        case 422:
          console.log('form validation error!', data)
        }

        //const data = await response.json()
        
        console.log('this is our response', response)

        responseValue['status'] = response.status
        responseValue['message'] = data.message
        responseValue['data'] = data.data

        console.log('our response is ', responseValue)

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = response.status
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postFetch: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const ourUrl = 'https://challenges-api.portal.yomenonline.ng/v0.1/quiz/getQuestion'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = error.status
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  generalPost: function (send_data, ourUrl) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/register'
    //const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/user/signup'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },
  generalGETWithParams: function (ourUrl, ourParams) {
    const getRequestOptions = {
      method: 'GET',
      params: ourParams
    }

    /* const postRequestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(send_data)
        }
    */
    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/register'
    //const ourUrl = 'http://z-ft-dev-guest.domain.nfmp.gov.ng/formOptions/contactUs'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, getRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  generalGET: function (ourUrl) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    /* const postRequestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }
 */
    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/register'
    //const ourUrl = 'http://z-ft-dev-guest.domain.nfmp.gov.ng/formOptions/contactUs'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, getRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postFetchCheckAnswer: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const ourUrl = 'https://challenges-api.portal.yomenonline.ng/v0.1/quiz/scoreQuestion'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postCreateUserAccount: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/register'
    const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/user/signup'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postGetSignupData: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/register'
    const ourUrl = 'http://z-ft-dev-guest.domain.nfmp.gov.ng/formOptions/contactUs'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postGetMerchandize: function (send_data) {
    const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/products/merchandize'
    //return this.makeRequest(ourUrl, 'GET')
    
    return this.generalPost(send_data, ourUrl)
  },
 
  postCreateWards: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/register'
    const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/user/signup'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postLoginUserAccount: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/user/signin'
    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/login'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postGetTransactionRef: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const ourUrl = 'https://payment-api.portal.yomenonline.ng/v0.1/payment/getTransactionRef'
    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/login'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  postUpdateSaveProfilePic: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/user/updateuserpic'
    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/login'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },
  
  postUpdateBios: function (send_data) {
    const getRequestOptions = {
      method: 'GET',
      params: { id:'1' }
    }

    const postRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(send_data)
    }

    //postRequestOptions['body'] = send_data

    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const ourUrl = 'https://user-account-api.portal.yomenonline.ng/v0.1/user/updatebios'
    //const ourUrl = 'https://demo-authentication.portal.yomenonline.ng/users/login'
    //const ourUrl = 'https://apimocha.com/yomen/questions'
    
    return fetch(ourUrl, postRequestOptions)
      .then(async (response) => {
        const data = await response.json()
        
        ////console.log('this is our response', response)
        // check for error response
        if (!response.ok) {
          
          // get error message from body or default to response status
          const error = (data && data.message) || response.status
          
          return Promise.reject(error)
        }

        responseValue['status'] = 200
        responseValue['message'] = 'Request successful'
        responseValue['data'] = data.data

        return responseValue
        //this.postId = data.data.id
      })
      .catch((error) => {
        //this.errorMessage = error
        
        console.error('There was an error!', error)
        responseValue['status'] = 400
        responseValue['message'] = error
        responseValue['data'] = error

        return responseValue
      })
  },

  saveProfilePic: function (send_data) {
    
    const [f] = send_data.ourfile
    const fileName = f.name
    const ext = fileName.split('.').pop()

    const s3 = new AWS.S3({
      correctClockSkew: true,
      endpoint: send_data.ourendpoint,
      accessKeyId: send_data.ouraccessKeyId,
      secretAccessKey: send_data.oursecretAccessKey,
      region: send_data.ourregion,
      logger: console
    })
    const folder = send_data.ourfolder
    const uploadRequest = new AWS.S3.ManagedUpload({
      params: { Bucket: send_data.ourbucket, Key: folder + send_data.ouruserId + '.' + ext, Body: f },
      service: s3
    })

    uploadRequest.on('httpUploadProgress', (event) => {
      const progressPercentage = Math.floor(event.loaded * 100 / event.total)

      //console.log('Upload progress ' + progressPercentage)
    })

    uploadRequest.send((err) => {
      if (err) {
        //console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
        //app.show_toast('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      } else {
        //console.log('Good upload')
      }
    })
  },

  saveDocument: function (param) {
    return this.makeRequest('https://z-dev-ft-authen-api.domain.nfmp.gov.ng/members/saveDocument', param, 'POST')
  },

  verifyAccount: function (param) {
    return this.makeRequest('https://z-dev-ft-authen-api.domain.nfmp.gov.ng/members/verifyAccount4Update', param, 'POST')
  },

  updateAccount: function (param) {
    return this.makeRequest('https://z-dev-ft-authen-api.domain.nfmp.gov.ng/members/updateAccount', param, 'POST')
  },

  getUpdateAccountData: function (param) {
    return this.makeRequest('https://z-dev-ft-authen-api.domain.nfmp.gov.ng/members/updateAccountData', param, 'POST')
  },

  handleFile: function (cntl, send_data) {
    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    let folder = ''

    switch (cntl) {
    case 'picture_upload0':
      folder = 'CAC/'
      break
    case 'picture_upload1':
      folder = 'GOVT-ID/'
      break
    case 'picture_upload2':
      folder = 'PASSPORT/'
      break
    }

    const f = send_data.ourfile
    const fileName = f.name
    const ext = fileName.split('.').pop()
    const s3 = new AWS.S3({
      correctClockSkew: true,
      endpoint: send_data.ourendpoint,
      accessKeyId: send_data.ouraccessKeyId,
      secretAccessKey: send_data.oursecretAccessKey,
      region: send_data.ourregion,
      logger: console
    })
    const uploadRequest = new AWS.S3.ManagedUpload({
      params: { Bucket: 'nfmp', Key: folder + send_data.membership_id + '.' + ext, Body: f },
      service: s3
    })

    uploadRequest.on('httpUploadProgress', (event) => {
      const progressPercentage = Math.floor(event.loaded * 100 / event.total)

      console.log('Upload progress ' + progressPercentage)
    })

    uploadRequest.send((err) => {
      if (err) {
        console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
        responseValue['status'] = 400
        responseValue['message'] = 'Upload NOT Successful'

        return responseValue
        //app.show_toast('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      } else {
        console.log('Good upload')
        responseValue['status'] = 200
        responseValue['message'] = 'Upload Successful'

        return responseValue
      }
    })
    /* uploadRequest.on('httpUploadProgress', function (event) {
      const progressPercentage = Math.floor(event.loaded * 100 / event.total)

      switch (cntl) {
      case 'picture_upload0':
        //$('#preview0').html('Upload progress ' + progressPercentage)
        console.log('preview 0 ', progressPercentage)
        break
      case 'picture_upload1':
        //$('#preview1').html('Upload progress ' + progressPercentage)
        console.log('preview1 Progress ', progressPercentage)
        break
      case 'picture_upload2':
        //$('#preview2').html('Upload progress ' + progressPercentage)
        console.log('preview2 progress', progressPercentage)
        break
      }
      //console.log('Upload progress ' + progressPercentage);
    }) */
  },

  getTransactionRef: function (send_data) {
    return this.postGetTransactionRef(send_data)
  },

  getNewQuestion: function (send_data) {

    return this.postFetch(send_data)
  },

  checkAnswer: function (send_data) {
    return this.postFetchCheckAnswer(send_data)
  },

  createUserAccount: function (send_data) {
    return this.postCreateUserAccount(send_data)
  },

  getSignupData: function () {
    return this.generalGET('http://z-ft-dev-guest.domain.nfmp.gov.ng/formOptions/createAccount')
    //return this.postGetSignupData(send_data)
  },

  getCreateAccountData: function () {
    return this.generalGET('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/formOptions/createAccount')
  },

  getWhistleBlowingData: function () {
    return this.generalGET('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/formOptions/whistleblowing')
  },

  getProducts: function () {
    return this.generalGET('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/products/organicFertilizer')
  },

  getDealers: function () {
    //return this.generalGET('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/dealers/')
    return this.makeRequest('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/dealers/', 'GET')
  },

  createAccount: function (param) {
    return this.makeRequest('https://z-dev-ft-authen-api.domain.nfmp.gov.ng/members/createAccount', param, 'POST')
  },
  //https://z-dev-ft-guest-api.domain.nfmp.gov.ng/dealers/search?LOB=Blender&state=kano&name=kano
  getDealersSearch: function (params) {
    return this.makeRequest('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/dealers/search', params, 'GETwithparams')
    //return this.generalGETWithParams('https://z-dev-ft-guest-api.domain.nfmp.gov.ng/dealers/search', params)
  },

  getMerchandize: function (send_data) {
    return this.postGetMerchandize(send_data)
  },
  
  createWards: function (send_data) {
    return this.postCreateWards(send_data)
  },

  loginUserAccount: function (param) {
    return this.makeRequest('https://z-dev-ft-authen-api.domain.nfmp.gov.ng/members/login', param, 'POST')
    //return this.postLoginUserAccount(send_data)
  },

  getDashboardDataFirstrun: function (param) {
    return this.makeRequest('https://z-dev-ft-members-api.domain.nfmp.gov.ng/account/firstrunDashboard', param, 'POSTWITHJWT')
    //return this.postLoginUserAccount(send_data)
  },

  getDashboardDataMainmember: function (param) {
    return this.makeRequest('https://z-dev-ft-members-api.domain.nfmp.gov.ng/account/MainmemberDashboard', param, 'POSTWITHJWT')
    //return this.postLoginUserAccount(send_data)
  },

  getDashboardDataFees: function (param) {
    return this.makeRequest('https://z-dev-ft-members-api.domain.nfmp.gov.ng/tables/firstrunDashboardFee', param, 'POSTWITHJWT')
    //return this.postLoginUserAccount(send_data)
  },

  userType() {
    const u_t = this.getSessionItem('process_data')
    
    if (u_t === null || u_t === undefined || u_t.data === undefined) {
      return 'Guest'
    }
    if (u_t && u_t.usertype === 'Guest') {
      return 'Guest'
    }
    if (u_t && u_t.usertype === 'Admin') {
      return 'Admin'
    }
    if (u_t && u_t.usertype === 'User') {
      return 'User'
    }
    if (u_t && u_t.usertype === 'Firstrun') {
      return 'Firstrun'
    }
    if ((u_t && u_t.usertype === 'Mainmember') || (u_t && u_t.usertype === 'Full Membership')) {
      return 'Mainmember'
    }
    const urole = u_t.usertype

    if (u_t && urole === null || urole === undefined || urole === undefined) {
      return 'Guest'
    }
    
    return ''
  },
  guestUser () {
    if (this.userType() === 'Guest') {
      return true
    } else {
      return false
    }
  },

  adminLoggedIn() {
    if (this.userType() === 'Admin') {
      return true
    } else {
      return false
    }
  },

  userLoggedIn() {
    if (this.userType() === 'User') {
      return true
    } else {
      return false
    }
  },

  userFirstrun() {
    if (this.userType() === 'Firstrun') {
      return true
    } else {
      return false
    }
  },

  userMainmember() {
    if (this.userType() === 'Mainmember') {
      return true
    } else {
      return false
    }
  },

  setSessionItem (key, value) {
    localStorage.setItem(key, value)

    const event = new CustomEvent('process_data_changed', {
      detail: {
        storage: JSON.parse(localStorage.getItem(key))
      }
    })

    // dispatch the event on the window object
    window.dispatchEvent(event)
  },

  getSessionItem (key) {
    return JSON.parse(localStorage.getItem(key))
  },

  adminUser () {
    if (this.userType() === 'admin') {
      return true
    } else {
      return false
    }
  },

  clearSession() {
    localStorage.clear()
  },

  logoutUser() {
    this.clearSession()

    return true
  },
  scrollTo(data) {
    if (data === 'top') {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }, 500)
    }
    if (data === 'bottom') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}