export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-briefcase', key: 'menu.blank', text: 'Quaterly Reporting', link: '/companyprofile' },
    { icon: 'mdi-briefcase', key: 'menu.blank', text: 'Sales Report', link: '/purchase' },
    { icon: 'mdi-briefcase', key: 'menu.blank', text: 'Product Evaluation', link: '/productevaluation' }
    /* { key: 'menu.levels', text: 'Assets & Products',
      items: [
        { text: 'General View', link: '/assets_products' },
        { text: 'Warehouses/Stores/Storage Facilities', link: '/warehouses' },
        { text: 'Representative Summary', link: '/representatives' },
        { text: 'Fertilizer Products Summary', link: '/products' },
        { text: 'Fertilizer Packages Summary', link: '/packages' }
      ]
    } */,
    { icon: 'mdi-receipt', key: 'menu.blank', text: 'Ledger', link: '' },
    { icon: 'mdi-cash-multiple', key: 'menu.blank', text: 'Transaction History', link: '/transactionhistory' },
    { icon: 'mdi-bell', key: 'menu.blank', text: 'Notification', link: '' }/* ,
    { icon: 'mdi-cog', key: 'menu.blank', text: 'Settings', link: '' }   */  
  ]
}]