<template>
  <div>
    <AssetsAndProducts />
  </div>
</template>

<script>
import AssetsAndProducts from '@/nfmp_components/AssetsAndProducts.vue'
export default {
  components: {
    AssetsAndProducts
  }
}
</script>