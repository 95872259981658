<template>
  <Packages />
</template>

<script>
import Packages from '@/components/ui/assetsandproducts/packages/Packages.vue'

export default {
  components: {
    Packages
  }
}
</script>