<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Ledger</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row justify="center">
      <v-col md="4" sm="12" class="pt-2">
        <v-alert border="bottom" outlined color="blue lighten-2" prominent>
          <v-list two-line color="grey lighten-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><div class="text-h5 blue--text">
                  {{ debit | nairaFormatter }}
                </div></v-list-item-title>
                <v-list-item-title>Total Debit</v-list-item-title>
                <v-list-item-subtitle>Amount paid so far</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon size="56" color="blue darken-2"> mdi-cash-plus </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-col>
      <v-col md="4" sm="12" class="pt-2">
        <v-alert border="bottom" outlined color="purple lighten-2" prominent>
          <v-list two-line color="grey lightens-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><div class="text-h5 purple--text">
                  {{ balance | nairaFormatter }}
                </div></v-list-item-title>
                <v-list-item-title>Balance</v-list-item-title>
                <v-list-item-subtitle>Total amount due</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon size="56" color="purple darken-2">
                  mdi-cash-check
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-col>
      <v-col md="4" sm="12" class="pt-2">
        <v-alert border="bottom" outlined color="red lighten-2" prominent>
          <v-list two-line color="grey lighten-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title><div class="text-h5 red--text">
                  {{ credit | nairaFormatter }}
                </div></v-list-item-title>
                <v-list-item-title>Total Credit</v-list-item-title>
                <v-list-item-subtitle>Amount billed so far</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon size="56" color="red darken-2"> mdi-cash-minus </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" class="pt-1">
        <v-alert
          border="left"
          color="red lighten-1"
          dense
          text
          type="warning"
        >
          You are strongly advice to make payment from your invoice page. This is for quick problem resolution
        </v-alert>
        <v-card>
          <v-data-table 
            :headers="headers"
            :items="tableData"
            sort-by="postDate,credit,debit"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="isInvoiceGenOK(item)"
                outlined
                color="green"
                @click="generateInvoice(item)"
              >
                Generate Invoice
              </v-btn>
              <div v-else>
                <v-btn
                  v-if="isInvoiceViewOK(item)"
                  outlined
                  @click="viewInvoice(item)"
                >
                  View Invoice
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData: {},
      currentLOBDetails: {},
      prePostData: {},
      pageData: {},
      tableLoading: true,
      singleSelect: true,
      tableNoDataText: 'Thus table has no record!',
      credit:0,
      balance:0,
      debit:0,

      snackbar: false,
      snackbartext: '',

      headers: [
        {
          text: 'Transaction Type',
          value: 'transaction_type'
        },
        { text: 'Description', value: 'description' },
        { text: 'Debit', value: 'debit' },
        { text: 'Credit', value: 'credit' },
        { text: 'Post Date', value: 'postDate' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      tableData: []
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
  },
  mounted() {},
  methods: {
    getTableData: function () {
      const url = httpServ.getPaymentUrl('/payment/ledger/member')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          result.data.forEach((item) => {
            this.credit += item.credit
            this.debit += item.debit
            this.balance = Math.abs(this.credit - this.debit)
            //console.log(item.payment_status)
          })
          this.tableData = result.data
        } 
      })
      this.tableLoading = false
    },
    generateInvoice: function(item) {
      const postData = this.prePostData

      postData.ledger_id = item.id
      postData.company_name = item.company_name
      postData.email = item.email
      postData.phone = item.phone
      postData.description = item.transaction_type

      //this.updateProfileBtnIsActive = true
      //this.updateProfileBtnText = 'Submiting ...'
      
      const url = httpServ.getPaymentUrl('/payment/remita/createInvoice')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          item.RRR = result.data.RRR
          item.transactionId = result.data.transaction_id
          item.status = 'new'
          this.snackbartext = 'Your invoice have generated successfully',
          this.snackbar = true
          //const { id } = item

          this.$router.push({ name: 'remitaInvoice', params:  {
            obj: { item }
          } })
        } else {
          this.snackbartext = 'Error: ' + result.message + '! You need to try again'
          this.snackbar = true
        }
      })
    },
    viewInvoice: function(item) {
      item.status = 'old'
      this.$router.push({ name: 'remitaInvoice', params:  {
        obj: { item }
      } })
    },
    isInvoiceGenOK: function(item) {
      if (item.transaction_id === null && item.credit > 0) {
        return true
      }  else {
        return false
      }
    },
    isInvoiceViewOK: function(item) {

      if (item.payment_status === 1) {
        return true
      }  else {
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
