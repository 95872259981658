<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Settings</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-card>
      <v-card-title>
        Whatsapp configuration
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" class="">
            Whatsapp Number: {{ whatsappNumber }}
          </v-col>
          <v-col cols="12" class="">
            <v-checkbox
              v-model="ex4"
              label="I want to recieve only important notice through Whatsapp"
              color="red"
              value="red"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="">
            I want to recieve only important notice through Whatsapp
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
//import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snactbartext:'',
      whatsappNumber:'loading...'
    }
  },
  created() {
    //this.memberData = httpServ.getSessionJson('memberData')
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
</style>
