<template>
  <div>
    <Warehouses />
  </div>
</template>

<script>
import Warehouses from '@/nfmp_components/Warehouses2.vue'
export default {
  components: {
    Warehouses
  }
}
</script>