export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-domain', key: 'menu.blank', text: 'Company Profile', link: '/companyprofile' },
    { icon: 'mdi-briefcase', key: 'menu.blank', text: 'Assets & Products', link: '/assets_products' },
    /* { key: 'menu.levels', text: 'Assets & Products',
      items: [
        { text: 'Warehouses/Stores/Storage Facilities' },
        { text: 'Representative Summary' },
        { text: 'Fertilizer Products Summary' },
        { text: 'Fertilizer Packages Summary' }
        { text: 'Menu Levels 2.2',
          items: [
            { text: 'Menu Levels 3.1' },
            { text: 'Menu Levels 3.2' }
          ]
        }
      ]
    }, */
    { icon: 'mdi-receipt', key: 'menu.blank', text: 'Ledger', link: '/ledger' },
    { icon: 'mdi-cash-multiple', key: 'menu.blank', text: 'Transaction History', link: '/transactionhistory' },
    { icon: 'mdi-bell', key: 'menu.blank', text: 'Notification', link: '' }/* ,
    { icon: 'mdi-cog', key: 'menu.blank', text: 'Settings', link: '' } */
  ]
}]