<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Notification</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-list three-line dense>
            <div v-for="(item, index) in notices" :key="index">
              <v-divider v-if="index > 0 && index < items.length" inset></v-divider>

              <v-list-item @click="">
                <v-list-item-avatar size="32" :color="item.color">
                  <v-icon dark small>{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle class="caption" v-text="item.body"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <v-list-item-action-text> {{ item.postDate | moment("from", "now", true) }} </v-list-item-action-text>
                  <v-chip small>{{ item.LOB }}</v-chip>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      notices:[],
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',
      items: []
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getNotices()
  },
  mounted() {},
  methods: {
    getNotices: function () {
      //const url = httpServ.getNotificationUrl('/webNotification/summarizedList')
      const url = httpServ.getNotificationUrl('/webNotification/fullList')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.notices = result.data
        //console.log(this.notices)
      })
    } 
  }
}
</script>

<style scoped>
</style>
