<template>
  <Layout is-demo>
    <div class="pb-5">
      <h1 class="text-h4">Representatives</h1>
    </div>
    <v-row dense>
      <v-col cols="12" class="">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            sort-by="id"
            class="elevation-1"
            :loading="tableLoading"
            loading-text="Loading... Please wait"
            fixed-header
            height="350px"
            :no-data-text="tableNoDataText"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!--<v-toolbar-title>My CRUD</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>-->
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 px-2"
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    >
                      Add new representative
                    </v-btn>
                  </template>
                  <v-card>
                    <v-toolbar color="green" dark dense flat>
                      <v-toolbar-title class="text-h5">
                        {{ formTitle }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-form v-model="valid">
                      <small
                        class="pa-2 grey--text"
                      >*indicates required field</small>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="first_name"
                              label="Firstname*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="last_name"
                              label="Lastname*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="designation"
                              label="Designation*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-select
                              v-model="warehouse_id_item"
                              return-object
                              :items="warehouse_id_arr"
                              item-text="title"
                              item-value="id"
                              label="Warehouse*"
                              required
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="email"
                              label="Email*"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            class="py-0"
                          >
                            <v-text-field
                              v-model="phone"
                              label="Phone*"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="close">
                        Close
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        outlined
                        :disabled="postWarehouseBtnIsActive"
                        @click="postWarehouseData()"
                      >
                        Submit Data
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="editItem(item)">
                mdi-file-document-edit
              </v-icon>
            </template>
            <template v-slot:no-data>
              <div class="caption">
                This table has no record! Try reloading it
              </div>
              <v-btn color="primary" outlined small @click="getTableData">
                Reload Data
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <BackButton />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import BackButton from './assets&products/BackButton'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout,
    BackButton
  },
  data: () => ({
    memberData: {},
    currentLOBDetails: {},
    prePostData: {},
    pageData: {},
    equipmentsData: [],

    dialog: false,
    dialogDelete: false,
    tableLoading: true,
    singleSelect: true,
    tableNoDataText: 'Thus table has no record!',

    snackbar: false,
    snackbartext: '',

    representative_id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone:'',
    designation:'',
    warehouse_id_item: {},
    warehouse_id_arr: [],

    valid: false,
    postWarehouseBtnIsActive: false,
    postWarehouseBtnText: 'Send',

    headers: [
      {
        text: 'FirstName',
        align: 'start',
        sortable: false,
        value: 'first_name'
      },
      { text: 'LastName', value: 'last_name' },
      { text: 'Phone', value: 'phone' },
      { text: 'Email', value: 'email' },
      { text: 'Warehouse Title', value: 'warehouse' },
      { text: 'Designation', value: 'designation' },
      { text: 'Modify', value: 'actions', sortable: false }
    ],
    tableData: [],

    editedIndex: -1
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'Add New Representative'
        : 'Modify Existing Representative'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.currentLOBDetails.memberStatus =
      this.memberData.lobs[this.memberData.displayLOB].membership_status
    this.currentLOBDetails.LOBId =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    this.currentLOBDetails.LOBname =
      this.memberData.lobs[this.memberData.displayLOB].line_of_business_name
    this.prePostData = {
      membership_id: this.memberData.membership_id,
      LOB_id:
        this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
    }
    this.getTableData()
    this.getPageData()
  },

  methods: {
    getTableData: function () {
      const url = httpServ.getMemberUrl('/tables/representative')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.tableData = result.data.representative
      })
      this.tableLoading = false
    },
    getPageData: function (mode) {
      const url = httpServ.getMemberUrl('/formOptions/representative')
      const resp = httpServ.postFetchWithToken(
        url,
        this.prePostData,
        this.memberData.jwt
      )

      resp.then((result) => {
        this.warehouse_id_arr = result.data.warehouse_id
      })
    },
    getNewData: function () {
      const data = {
        status: 'new',
        first_name: this.first_name,
        last_name: this.last_name,
        warehouse_id: this.warehouse_id_item.id,
        phone: this.phone,
        email: this.email,
        designation: this.designation,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    getUpdatedData: function () {
      const data = {
        status: 'update',
        representative_id:this.representative_id,
        first_name: this.first_name,
        last_name: this.last_name,
        warehouse_id: this.warehouse_id_item.id,
        phone: this.phone,
        email: this.email,
        designation: this.designation,
        membership_id: this.memberData.membership_id,
        line_of_business:
          this.memberData.lobs[this.memberData.displayLOB].line_of_business_id
      }

      return data
    },
    postWarehouseData: function (mode) {
      const postData =
        this.editedIndex > -1 ? this.getUpdatedData() : this.getNewData()

      this.updateProfileBtnIsActive = true
      this.updateProfileBtnText = 'Submiting ...'

      const url = httpServ.getMemberUrl('/account/representative')
      const resp = httpServ.postFetchWithToken(
        url,
        postData,
        this.memberData.jwt
      )

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Entry was successful!'
          this.snackbar = true
          this.signinBtnText = 'Successful'
          this.close()
          this.getTableData()
        } else {
          //alert(result.message)
          this.snackbartext = result.message + ': ' + result.data.error
          this.snackbar = true
          this.signinBtnStatus = false
          this.signinBtnText = 'Update failed!'
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item)
      /*this.editedItem = Object.assign({}, item)*/
      this.representative_id = item.id
      this.first_name = item.first_name
      this.last_name = item.last_name
      this.phone = item.phone
      this.warehouse_id_item.id = item.warehouse_id
      this.email = item.email
      this.designation = item.designation
      this.mode = 'update'
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.first_name = ''
        this.last_name = ''
        this.warehouse_id_item.id = 0
        this.phone = ''
        this.email = ''
        this.designation = ''
        this.editedIndex = -1
      })
    }
  }
}
</script>

<style scoped>
</style>