<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Viewport</h1>
      <v-divider class="my-5"></v-divider>
    </div>
    <v-card
      class="mx-auto grey lighten-1"
      max-width="700" 
      color="green-text"
      dark
      rounded="xl"
    >
      <v-row class="mx-0" style="overflow:hidden;"> 
        <v-col
          md="5"
          sm="12"
          class="green"
          align="center"
          justify="center"
        >
          <H2 class="text-h4 ">REMITA</h2>
          <div>Platform Payment</div>
          <v-divider class="my-2" color="white white--text"></v-divider>
          <div>Issuer:</div>
          <H2 class="text-h5">NFMP</h2>
          <v-divider class="my-2" color="white-text"></v-divider>
          <div>Amount:</div>
          <H2 class="text-h5">4000</h2>
        </v-col>

        <v-col md="7" sm="12" color="green--text">
          <div class="d-flex justify-center" color="green--text">
            <v-icon
              size="90"
              color="green darken-2"
            >
              mdi-check-circle
            </v-icon>
          </div>
          <div class="text-h5 d-flex justify-center" color="green--text">
            RRR: 555555555
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="py-2">
            <b>Status:</b> You are sucesssfull
          </div>
          <div class="py-2">
            <b>Transaction ID:</b> You are sucesssfull
          </div>
          <div class="py-2">
            <b>Payment Date:</b> You are sucesssfull
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
//import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',
      showAlert:true
    }
  },
  created() {
    //this.memberData = httpServ.getSessionJson('memberData')
    const urlParams = new URLSearchParams(window.location.search)
    const RRR = urlParams.get('RRR')

    console.log(RRR)
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
</style>
