<template>
  <div>
    <v-btn @click="moveto">Continue</v-btn>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created() {
    this.$router.push({ name: 'dashboard' })
  },
  methods:{
    moveto: function() {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>