<template>
  <v-layout class="fill-height">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      width="260"
      class="elevation-1"
      :right="$vuetify.rtl"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center">
          <img
            src="/images/v-logo-small1.png"
            height="48"
            alt="logo"
            class="mr-1 brand-logo"
          />
        </div>
      </template>
      <div class="caption  text-center text-uppercase white--text pa-1">{{ portalType }} Portal</div>
      <div class="ma-1">
        <v-select
          v-model="selectedLOB"
          dense
          return-object
          :items="LOBoptions"
          item-text="text"
          item-value="id"
          label="Current Line Of Business"
          required
          outlined
          hide-details
          max-width="20px"
          @change="changeLOB"
        ></v-select>
      </div>
      <!-- Navigation menu -->
      <main-menu :menu="menu" />
      <v-divider class="my-1"></v-divider>
      <v-list nav dense>
        <v-list-item link to="/settings">
          <v-list-item-icon>
            <v-icon small>mdi-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/switchLineOfBusiness">
          <v-list-item-icon>
            <v-icon small>mdi-settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>switchLineOfBusiness</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-system-bar app window class="system-bar d-flex d-sm-flex d-md-none">
      <v-row>
        <v-col class="text-center  white--text "> 
          {{ companyName }} 
        </v-col>
      </v-row>
    </v-system-bar>
    <!-- Toolbar -->
    <v-app-bar app flat color="surface">
      <v-card class="flex-grow-1 d-flex pa-1 mt-5 mx-1 secondary">
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon
              class="d-lg-none"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <div class="d-none d-md-flex  text-uppercase white--text">
              <h3> {{ companyName }}</h3>
            </div>
            <v-spacer class=""></v-spacer>
            <v-spacer class="d-block d-sm-none"></v-spacer>
            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications />
            </div>

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-container fluid class="pt-3">
      <router-view class="py-2 fill-height"></router-view>

      <!-- DEMO PURPOSES DEFAULT ROUTER VIEW -->
      <div v-if="isDemo" class="py-2 fill-height">
        <slot></slot>
      </div>
    </v-container>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import config from '@/configs'
import MainMenu from '../menus/MainMenu'
import ToolbarUser from '../toolbars/ToolbarUser'
import ToolbarNotifications from '../toolbars/ToolbarNotifications'

import httpServ from '@/services/HTTPService'
import menu from '../menus/menu'
import mainMemberMenu from '../menus/mainMemberMenu'
import firstrunMenu from '../menus/firstrunMenu'
import sideMenu from '../menus/sideMenu'

export default {
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarNotifications
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      portalType: '',
      companyName: '',
      currentLOBDetails:{},
      memberStatus:'',
      mainMemberMenu,
      firstrunMenu,
      menu,
      LOBoptions:[],
      selectedLOB:'main_line_of_business',
      sideMenu,
      config,
      drawer: null,

      showSearch: false,
      businessLineId: '',
      select: null,
      
      switchItems: [],
      userProfile: '',
      snackbar: false,
      snackbartext: ''
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.companyName = this.memberData.company_name
    this.portalType = this.memberData.lobs[this.selectedLOB].membership_status
    this.changeMenu()

    /*this.$watch('selectedLOB', (newLOB) => {
      console.log('selectedLOB changed')
      const currentLOB = newLOB.id

      this.changeMenu()
      this.portalType = this.memberData.lobs[currentLOB].membership_status
      this.$parent.getTableData(currentLOB)
    })*/
    this.currentLOBDetails = this.memberData.lobs[this.memberData.displayLOB]
    this.LOBoptions.push({
      text:this.memberData.lobs['main_line_of_business'].line_of_business_name,
      id:'main_line_of_business'
    })
    if (this.memberData.lobs['second_line_of_business'].line_of_business_id > 0) {
      this.LOBoptions.push({
        text:this.memberData.lobs['second_line_of_business'].line_of_business_name,
        id:'second_line_of_business'
      })
    }
    this.selectedLOB = this.memberData.displayLOB
  },
  methods: {
    changeMenu: function() {
      //console.log('change menu called')
      if (this.portalType === 'Firstrun') {
        this.menu = this.firstrunMenu
      } else {
        this.menu = this.mainMemberMenu  
      }
    },
    changeLOB: function() {
      this.snackbartext = 'Switching current line of business'
      this.snackbar = true
      this.memberData.displayLOB = this.selectedLOB.id
      this.memberData = httpServ.getSessionJson('memberData') 
      this.memberData.displayLOB = this.selectedLOB.id
      httpServ.setSessionJson('memberData',this.memberData)
      
      setTimeout(() => {
        if (this.$route.name === 'dashboard') {
          this.$router.push({ name: 'dummyDashboard' })
        } else {
          this.$router.push({ name: 'dashboard' })
        }
      }, 3000)
    }
  }
}
</script>
<style scoped>
.select .v-input__slot {
   padding-right: 10px;
   max-width: 30px;
  }
.lob-bg {
  background-color: #0a6003 !important;
}

.lob-option {
  background-color: #49e80f !important;
}
.font-small {
  font-size: 13px !important;
}
.current-bus-line {
  text-align: right !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
}
.system-bar {
  background-color: #8b8988 !important;
}
.system-bar select {
  background-color: white !important;
}
.elevation-1 {
  background-color: #a5d6a7 !important;
}
.app-title {
  font-size: 30px;
}

.brand-logo {
  width: 240px !important;
  height: 30px !important;
}

.top-switch {
  background-color: black !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.text-h4,
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  font-weight: 400 !important;
}
</style>