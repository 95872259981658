export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-domain', key: 'menu.blank', text: 'Company Profile', link: '/companyprofile' },
    { icon: 'mdi-briefcase', key: 'menu.blank', text: 'Assets & Products', link: '/assets_products' },
    { icon: 'mdi-receipt', key: 'menu.blank', text: 'Ledger', link: '/ledger' },
    { icon: 'mdi-history', key: 'menu.blank', text: 'RRR Transctions History', link: '/RRRtransactionhistory' },
    { icon: 'mdi-update', key: 'menu.blank', text: 'Legacy Transactions History', link: '/Legacytransactionhistory' },
    { icon: 'mdi-bell', key: 'menu.blank', text: 'Notification', link: '/notification' } ,
    { icon: 'mdi-logout', key: 'menu.blank', text: 'Logout', link: '/logout' } 
  ]
}]